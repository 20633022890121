import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Form,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {  createCoupon } from '../actions/couponActions'
import { COUPON_CREATE_RESET } from '../constants/couponConstants'
import { allCategories } from '../actions/categoriesAction'

const CouponEditScreen = () => {
  const history = useHistory()
  

  const [name, setName] = useState('')
  const [discount, setDiscount] = useState(0);
  const [quantity, setQuantity] = useState();
  const [expiryDate, setExpiryDate] = useState(new Date().toISOString().slice(0, 10));
  const [minimumPurchase, setMinimumPurchase] = useState(0);
  const [categories, setCategories] = useState([]);
  const [product, setProduct] = useState("");
  const [emails, setEmails] = useState("");
  const [contact, setContact] = useState("");
  const [isActive, setIsActive] = useState(false)

  const categorysAll = useSelector((state) => state.categoryAll);
  const { loading:loadingCategories, categories:productCategories } = categorysAll;



  const dispatch = useDispatch()

  const couponCreate = useSelector((state) => state.couponCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
} = couponCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: COUPON_CREATE_RESET });

    if (!userInfo || !userInfo.isAdmin) {
        history.push("/login");
    }
    if (successCreate) {
        history.push("/admin/couponlist");
    }
}, [dispatch, history, userInfo, successCreate]);

useEffect(() => {
  dispatch(allCategories());
}, [dispatch]);


const handleSelectCategories = (selectedOptions) => {
  if(selectedOptions.length===0){
    setCategories([])
    return
  }
    const tempCategories = [];
    if(selectedOptions[0].value==="All"){
      setCategories(productCategories.map(category => category.name))
    }else{
      for (let i=0; i<selectedOptions.length; i++) {
        tempCategories.push(selectedOptions[i].value);
      }
      tempCategories.filter(category => category !== "All")
      setCategories(tempCategories);
    }
}

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
        createCoupon({
        name,
        discount,
        quantity,
        expiryDate,
        minimumPurchase,
        categories,
        product,
        emails,
        contact,
        isActive
      })
    )
  }
  
  return (
    <>
      <Link to='/admin/couponList' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Create Coupon</h1>
  
        {(loadingCreate ||loadingCategories) ? (
          <Loader />
        ) : errorCreate ? (
          <Message variant='danger'>{errorCreate}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <FormGroup controlId='name'>
              <FormLabel>Name</FormLabel>
              <FormControl
                type='text'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              ></FormControl>
            </FormGroup>
            <FormGroup controlId='discount'>
              <FormLabel>Discount</FormLabel>
              <FormControl
                type='number'
                placeholder='Enter Discount Percentage Number'
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                required
              ></FormControl>
            </FormGroup>
            <FormGroup controlId='quantity'>
              <FormLabel>Quantity</FormLabel>
              <FormControl
                type='number'
                placeholder='leane blank for no limit'
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              ></FormControl>
            </FormGroup>
            <FormGroup controlId='expiryDate'>
              <FormLabel>Expiry Date</FormLabel>
              <FormControl
                type='date'
                placeholder='Expiry Date'
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                required
              ></FormControl>
            </FormGroup>
            <FormGroup controlId='minimumPurchase'>
              <FormLabel>Minimum Purchase Amount</FormLabel>
              <FormControl
                type='number'
                placeholder='Enter minimum purchase'
                value={minimumPurchase}
                onChange={(e) => setMinimumPurchase(e.target.value)}
                required
              ></FormControl>
            </FormGroup>
            <FormGroup controlId='categories'>
              <FormLabel>Valid Categories</FormLabel>
              <Form.Control
                type="text"
                placeholder="Select Category below"
                defaultValue={categories.join(", ")}
                readOnly
              />
              <Form.Control
                    as="select"
                    value={categories}
                    multiple={true} 
                    onChange={(e)=> {handleSelectCategories(e.target.selectedOptions)}}
                    required
                >
                  <option value={"All"}>
                            {"All"}
                        </option>
                    {productCategories?.map((category) => (
                        <option value={category.name} key={category.name}>
                            {category.name}
                        </option>
                    ))}
                </Form.Control>
                <Form.Text muted> hold ctrl or command for multiple select</Form.Text>
            </FormGroup>
            <FormGroup controlId='product'>
              <FormLabel>Valid Products</FormLabel>
              <FormControl
                type='text'
                placeholder='ex: productId1, productId2....'
                value={product}
                onChange={(e) => setProduct(e.target.value)}
              ></FormControl>
              <Form.Text muted>Leave Blank to enable for all products under selected categories</Form.Text>
            </FormGroup>
            <FormGroup controlId='emails'>
              <FormLabel>Emails</FormLabel>
              <FormControl
                type='text'
                placeholder='ex: email1@email.com, email2@email.com...'
                value={emails}
                onChange={(e) => setEmails(e.target.value)}
              ></FormControl>
              <Form.Text muted>Leave Blank to enable for all</Form.Text>
            </FormGroup>
            <FormGroup controlId='contacts'>
              <FormLabel>Contacts</FormLabel>
              <FormControl
                type='text'
                placeholder='ex: 9876543210, 8976544321...'
                value={contact}
                onChange={(e) => setContact(e.target.value)}
              ></FormControl>
              <Form.Text muted>Leave Blank to enable for all</Form.Text>
            </FormGroup>

            <FormGroup controlId='isActive'>
              <FormLabel>Is Active</FormLabel>
              <FormControl
                type='checkbox'
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              ></FormControl>
            </FormGroup>

            <Button type='submit' variant='primary'>
              Create
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default CouponEditScreen
