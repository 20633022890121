import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import {
    Row,
    Col,
    ListGroup,
    Image,
    Card,
    ListGroupItem,
    Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { getOrderDetails, deliverOrder, markAsPaidOrder, sendInvoice } from "../actions/orderActions";
import {
    ORDER_PAY_RESET,
    ORDER_DELIVER_RESET,
} from "../constants/orderConstants";
import { CART_CLEAR_ITEMS } from "../constants/cartConstants";

const loadScript = (url) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.async = true;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
};

const OrderScreen = () => {
    const history = useHistory();
    const { id } = useParams();

    const dispatch = useDispatch();

    const orderDetails = useSelector((state) => state.orderDetails);
    const { order, loading, error } = orderDetails;

    const orderPay = useSelector((state) => state.orderPay);
    const { loading: loadingPay, success: successPay } = orderPay;

    const orderSendInvoice = useSelector((state) => state.orderSendInvoice) 
    const {  loading:sendInvoiceLoading ,success: successSentInvoice, error: errorSentInvoice } = orderSendInvoice;

    const orderDeliver = useSelector((state) => state.orderDeliver);
    const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const resetCart = query ? query.get("resetCart") : "no";

    const [txnId, setTxnId] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    


    const displayPaytm = async () => {
        const res = await loadScript(
            window.location.host.endsWith("gehnagali.com")
                ? "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/fKrnxJ12832476516924.js"
                : "https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/KBIzlH25885636311178.js"
        );
        if (!res) {
            alert("ERR");
        }
        let config = {
            root: "",
            flow: "DEFAULT",
            data: {
                orderId: order.orderId /* update order id */,
                token: order.txnToken /* update token value */,
                tokenType: "TXN_TOKEN",
                amount: order.totalPrice /* update amount */,
            },
            handler: {
                notifyMerchant: function (eventName, data) {
                    console.log("notifyMerchant handler function called");
                    console.log("eventName => ", eventName);
                    console.log("data => ", data);
                },
            },
        };
        if (window.Paytm && window.Paytm.CheckoutJS) {
            window.Paytm.CheckoutJS.onLoad(
                function excecuteAfterCompleteLoad() {
                    // initialze configuration using init method
                    window.Paytm.CheckoutJS.init(config)
                        .then(function onSuccess() {
                            // after successfully updating configuration, invoke JS Checkout
                            window.Paytm.CheckoutJS.invoke();
                        })
                        .catch(function onError(error) {
                            console.log("error => ", error);
                        });
                }
            );
        }
    };

    useEffect(() => {
        if(successSentInvoice){
            alert("Invoice Sent")
        }
        if(errorSentInvoice){
            alert("Something Went wrong while sending invoice")
        }
    }, [successSentInvoice, errorSentInvoice])


    useEffect(() => {
        if (!userInfo) {
            history.push("/login");
        }

        if (!order || successPay || successDeliver || order._id !== id) {
            dispatch({ type: ORDER_PAY_RESET });
            dispatch({ type: ORDER_DELIVER_RESET });

            dispatch(getOrderDetails(id));
        }
    }, [dispatch, id, history, userInfo, successPay, order, successDeliver]);

    useEffect(() => {
        if(resetCart==="yes"){
            dispatch({
                type: CART_CLEAR_ITEMS,
            });
            localStorage.removeItem("cartItems");
        }
    }, [dispatch, resetCart]);

    const deliveredHandler = () => {
        dispatch(deliverOrder(order));
    };
    const markAsPaidHandler = () => {
        dispatch(markAsPaidOrder(order, txnId, paymentMode));
    };
    const sendInvoiceHandler = () => {
        dispatch(sendInvoice(order));
    };

    return loading ? (
        <Loader />
    ) : error ? (
        <Message variant="danger">{error}</Message>
    ) : (
        <>
            <h1>Order {order._id}</h1>
            {order.isPaid&&<h5 style={{color:'gray'}}>Please Note: You'll recieve invoice in your email within few hours</h5>}
            <Row>
                <Col md={8}>
                    <ListGroup variant="flush">
                        <ListGroupItem>
                            <h2>Shipping</h2>
                            <p>
                                <strong>Name: </strong> {order.user.name}
                            </p>
                            <p>
                                <strong>Email: </strong>
                                <a href={`mailto:${order.user.email}`}>
                                    {order.user.email}
                                </a>
                            </p>
                            <p>
                                <strong>Contact: </strong> {order.user.phone}
                            </p>
                            <p>
                                <strong>Address: </strong>
                                {order.shippingAddress.phone},{" "}
                                {order.shippingAddress.address},{" "}
                                {order.shippingAddress.city}{" "}
                                {order.shippingAddress.postalCode},{" "}
                                {order.shippingAddress.country}
                            </p>
                            {order.isDelivered ? (
                                <Message variant="success">
                                    Delivered on {order.deliveredAt}
                                </Message>
                            ) : (
                                <Message variant="danger">
                                    Not Delivered
                                </Message>
                            )}
                        </ListGroupItem>

                        <ListGroupItem>
                            <h2>Payment Method</h2>
                            <p>
                                <strong>Method: </strong>
                                {order.paymentMethod}
                            </p>
                            {order.isPaid ? (
                                <Message variant="success">
                                    Paid on {order.paidAt}
                                </Message>
                            ) : (
                                <Message variant="danger">Not paid</Message>
                            )}
                        </ListGroupItem>

                        <ListGroupItem>
                            <h2>Order Items</h2>
                            {order.orderItems.length === 0 ? (
                                <Message>Order is empty</Message>
                            ) : (
                                <ListGroup variant="flush">
                                    {order.orderItems.map((item, index) => (
                                        <ListGroup.Item key={index}>
                                            <Row>
                                                <Col md={1}>
                                                    <Image
                                                        src={item.image}
                                                        alt={item.name}
                                                        fluid
                                                        rounded
                                                    />
                                                </Col>
                                                <Col>
                                                    <Link
                                                        to={`/product/${item.product}`}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                </Col>
                                                <Col md={4}>
                                                    {item.qty} x &#8377;
                                                    {item.price} = &#8377;
                                                    {item.qty * item.price}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            )}
                        </ListGroupItem>
                    </ListGroup>
                </Col>

                <Col>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroupItem>
                                <h2>Order Summary</h2>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col>Items</Col>
                                    <Col>&#8377;{order.itemsPrice}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col>Shipping</Col>
                                    <Col>&#8377;{order.shippingPrice}</Col>
                                </Row>
                            </ListGroupItem>
                            <ListGroupItem>
                                <Row>
                                    <Col>Tax</Col>
                                    <Col>&#8377;{order.taxPrice}</Col>
                                </Row>
                            </ListGroupItem>
                            {order?.discount && <ListGroupItem>
                                <Row>
                                <Col>Discount</Col>
                                <Col>&#8377;{(order.discount||0).toFixed(2)}</Col>
                                </Row>
                            </ListGroupItem>}
                            <ListGroupItem>
                                <Row>
                                    <Col>Total</Col>
                                    <Col>&#8377;{order.totalPrice}</Col>
                                </Row>
                            </ListGroupItem>
                            {!order.isPaid && (
                                <ListGroupItem>
                                    {loadingPay && <Loader />}
                                    {false ? (
                                        <Loader />
                                    ) : (
                                        <Button
                                            type="button"
                                            className="btn-block"
                                            onClick={displayPaytm}
                                        >
                                            Pay Now
                                        </Button>
                                    )}
                                </ListGroupItem>
                            )}
                            {loadingDeliver && <Loader />}
                            <ListGroupItem>
                                <h3>Admin Options</h3>
                            </ListGroupItem>
                            {userInfo &&
                                userInfo.isAdmin &&
                                !order.isPaid && (
                                    <ListGroupItem>
                                        <div className="adminOptionsMarkAsPaidFields">
                                            <div className="markPaidFields">
                                                <label>Enter Transaction Id: </label>
                                            <input type="text" id="txnId" name="txnId" placeholder="Transaction ID" onChange={(e) => (setTxnId(e.target.value))} value={txnId}></input>
                                            </div>
                                            <div className="markPaidFields">
                                                <label>Enter Payment mode: </label>
                                            <input type="text" id="paymentMode" name="paymentMode" placeholder="Payment Mode" onChange={(e) => (setPaymentMode(e.target.value))} value={paymentMode}></input>

                                            </div>
                                        </div>
                                        <Button
                                            type="button"
                                            className="btn btn-block"
                                            onClick={markAsPaidHandler}
                                        >
                                            Mark As Paid
                                        </Button>
                                    </ListGroupItem>
                                )}
                                {userInfo &&
                                userInfo.isAdmin &&
                                order.isPaid &&  (
                                    <ListGroupItem>
                                        <Button
                                            type="button"
                                            className="btn btn-block"
                                            onClick={sendInvoiceHandler}
                                        >
                                            Send Invoice
                                        </Button>
                                        {sendInvoiceLoading && <Loader/>}
                                    </ListGroupItem>
                                )}
                            {userInfo &&
                                userInfo.isAdmin &&
                                order.isPaid &&
                                !order.isDelivered && (
                                    <ListGroupItem>
                                        
                                        <Button
                                            type="button"
                                            className="btn btn-block"
                                            onClick={deliveredHandler}
                                        >
                                            Mark As Delivered
                                        </Button>
                                    </ListGroupItem>
                                )}
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default OrderScreen;
