import React from "react";
import { Link } from "react-router-dom";

const ReturnAndExchangePolicyScreen = () => {
    return (
        <>
            <Link to="/" className="btn btn-light">
                Go Back
            </Link>
            <p>
                <br />
            </p>

            <h5>Refund policy</h5>
            <p>
                For any claims of missing or defective product, please make a
                complete un-boxing video of the delivered parcel and send on{" "}
                <a data-fr-linked="true" href="mailto:connect@gehnagali.com">
                    connect@gehnagali.com
                </a>{" "}
                mentioning your order ID, we will guide you thereafter.
            </p>

            <p>
                If there is no defect, there is NO RETURN, EXCHANGE &amp; REFUND
                policy.
            </p>

            <p>
                To be eligible for a return, your item must be in the same
                condition that you received it, unworn or unused, with tags, and
                in its original packaging. You&rsquo;ll also need the receipt or
                proof of purchase.
            </p>

            <p>
                To start a return, you can write us on connect@gehnagali,
                mentioning your order ID. If your return is accepted,
                we&rsquo;ll send you a return shipping label, as well as
                instructions on how and where to send your package. Items sent
                back to us without first requesting a return will not be
                accepted.
            </p>

            <br />
            <h5>Damages and issues</h5>
            <p>
                Please inspect your order the moment you recieve and contact us
                immediately if the item is defective, damaged or if you receive
                the wrong item, so that we can evaluate the issue and make it
                right.
            </p>

            <br />
            <h5>Exceptions / non-returnable items</h5>
            <p>
                Certain types of items cannot be returned, like : custom
                products ( such as special orders or personalized items), and
                SALE items. Please get in touch if you have questions or
                concerns about your specific item.
            </p>

            <p>
                Unfortunately, we cannot accept returns on sale items or gift
                cards.
            </p>

            <br />
            <h5>Exchanges</h5>
            <p>
                The fastest way to ensure you get what you want is to return the
                item you have, and once the return is accepted, make a separate
                purchase for the new item.
            </p>

            <br />
            <h5>Refunds</h5>
            <p>
                We will notify you once we&rsquo;ve received and inspected your
                return, and let you know if the refund was approved or not. If
                approved, you&rsquo;ll be automatically refunded on your
                original payment method. Please remember it can take some time
                for your bank or credit card company to process and post the
                refund too.
            </p>

            <br />

            <h5>Cancellation&nbsp;</h5>
            <p>Orders once placed cannot be cancelled.</p>
        </>
    );
};

export default ReturnAndExchangePolicyScreen;
