import axios from 'axios'
import {
  CART_ADD_ITEM,
  CART_REMOVED_ITEM,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SALE_VALIDITY
} from '../constants/cartConstants'

export const addToCart = (id, qty, color, specialDiscoutedProduct=false, salePrice=null) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/products/${id}`)
  const selectedVariant = data.productVariants.find(variant => variant.color === color)
  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: data._id,
      color: selectedVariant.color,
      name: `${data.name}${selectedVariant.color==="default"?"": ` (${selectedVariant.color})`}`,
      image: selectedVariant.image.split(",")[0],
      price: specialDiscoutedProduct?salePrice:selectedVariant.price,
      countInStock: selectedVariant.countInStock,
      qty,
      specialDiscoutedProduct
    },
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const removeFromCart = (id, color, specialDiscoutedProduct) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVED_ITEM,
    payload: {id, color, specialDiscoutedProduct},
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  })

  localStorage.setItem('shippingAddress', JSON.stringify(data))
}

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  })

  localStorage.setItem('paymentMethod', JSON.stringify(data))
}

export const saveSaleValidity = (data) => (dispatch) => {
  dispatch({
    type: CART_SALE_VALIDITY,
    payload: data,
  })

}

