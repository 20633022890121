import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Form,
    Button,
    FormGroup,
    FormLabel,
    FormControl,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import { saveShippingAddress } from "../actions/cartActions";
import { savePaymentMethod } from "../actions/cartActions";

const ShippingScreen = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const { shippingAddress } = cart;
    const [phone, setPhone] = useState(shippingAddress.phone);
    const [address, setAddress] = useState(shippingAddress.address);
    const [city, setCity] = useState(shippingAddress.city);
    const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
    const [country, setCountry] = useState(shippingAddress.country);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(
            saveShippingAddress({ phone, address, city, postalCode, country })
        );
        dispatch(savePaymentMethod("Paytm Payment Gateway"));

        history.push("/placeorder");
    };

    return (
        <FormContainer>
            <CheckoutSteps step1 step2 />
            <h1>Shipping</h1>
            <Form onSubmit={submitHandler}>
                <FormGroup controlId="phone">
                    <FormLabel>Phone no.</FormLabel>
                    <FormControl
                        type="tel"
                        pattern="^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$"
                        placeholder="Enter Phone no."
                        value={phone}
                        required
                        onChange={(e) => setPhone(e.target.value)}
                    ></FormControl>
                </FormGroup>
                <FormGroup controlId="address">
                    <FormLabel>Address</FormLabel>
                    <FormControl
                        type="text"
                        placeholder="Enter address"
                        value={address}
                        required="true"

                        onChange={(e) => setAddress(e.target.value)}
                    ></FormControl>
                </FormGroup>
                <FormGroup controlId="city">
                    <FormLabel>City</FormLabel>
                    <FormControl
                        type="text"
                        placeholder="Enter city"
                        value={city}
                        required="true"

                        onChange={(e) => setCity(e.target.value)}
                    ></FormControl>
                </FormGroup>
                <FormGroup controlId="postalcode">
                    <FormLabel>Postal Code</FormLabel>
                    <FormControl
                        type="text"
                        placeholder="Enter postal code"
                        value={postalCode}
                        required="true"

                        onChange={(e) => setPostalCode(e.target.value)}
                    ></FormControl>
                </FormGroup>
                <FormGroup controlId="country">
                    <FormLabel>Country</FormLabel>
                    <FormControl
                        type="text"
                        placeholder="Enter country"
                        value={country}
                        required="true"

                        onChange={(e) => setCountry(e.target.value)}
                    ></FormControl>
                </FormGroup>

                <Button type="submit" variant="primary">
                    Continue
                </Button>
            </Form>
        </FormContainer>
    );
};

export default ShippingScreen;
