import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
// import Rating from "./Rating";

const Product = ({ product }) => {
    product.productVariants = product.productVariants.filter(variant => variant.showVarient)
  let variantWithDiscount =  Math.max(...product.productVariants.map(variant => variant.discount))
    return (
        <>
          {variantWithDiscount>0 && <div className="dis-ribbon"><span>{variantWithDiscount.toFixed(0)} % Off</span></div>}

            <Card className="my-3 p-3 rounded">
                <Link
                    to={`/product/${product._id}`}
                >
                    {/* add fixed height for image */}
                    <Card.Img 
                    height={"300px"}
                        src={product.productVariants[0].image.split(",")[0]}
                    />
                </Link>

                <Card.Body>
                    <Link
                        to={`/product/${product._id}`}
                    >
                        <Card.Title as="div">
                            <strong>{product.name}</strong>
                        </Card.Title>
                    </Link>
                    {/* <Card.Text as="div">
                        <Rating
                            value={product.rating}
                            text={`${product.numReviews} reviews`}
                        />
                    </Card.Text> */}

                    {product.productVariants[0].discount > 0 ? (
                        <Card.Text as="h3" className="card_text_div">
                            <span className="original_price">
                                &#8377;{product.productVariants[0].originalPrice}
                            </span>
                            <span className="sale_price">
                                &#8377;{product.productVariants[0].price}
                            </span>
                        </Card.Text>
                    ) : (
                        <Card.Text as="h3" className="card_text_div">
                            &#8377;{product.productVariants[0].price}
                        </Card.Text>
                    )}
                </Card.Body>
            </Card>
        </>
    );
};
export default Product;