import React from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Image,
  FormControl,
  Button,
  Card,
} from 'react-bootstrap'
import { addToCart, removeFromCart } from '../actions/cartActions'
import { useEffect } from 'react'

const CartScreen = () => {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const query = new URLSearchParams(location.search);
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const qty = query ? Number(query.get("qty")) : 1
  const color = query ? query.get("color") : "default"
  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart
  useEffect(() => {
    if (id) {
      dispatch(addToCart(id, qty, color))
    }
  }, [dispatch, id, qty, color])

  const removeFromCartHandler = (id, color) => {
    dispatch(removeFromCart(id, color, false))
  }

  const checkoutHandler = () => {
    if (userInfo) {
      history.push('/shipping')
    }
    else{history.push('/login?redirect=shipping')}
  }

  return (
    <Row>
      <Col md={8}>
        <h1>Shopping Cart</h1>
        {(<>
          <div className='message'>
            <Link to='/'>Continue Shopping</Link>
          </div>

          <ListGroup variant='flush'>
            {cartItems.map((item) => (
              <ListGroupItem key={item.product+item.color}>
                <Row>
                  <Col md={2}>
                    <Image src={item.image} alt={item.name} fluid rounded />
                  </Col>
                  <Col md={3}>
                    <Link to={`/product/${item.product}`}>{item.name}</Link>
                  </Col>
                  <Col md={2}>&#8377;{item.price}</Col>
                  <Col>
                    <FormControl
                      as='select'
                      value={item.qty}
                      onChange={(e) =>
                        dispatch(
                          addToCart(item.product, Number(e.target.value), item.color)
                        )
                      }
                    >
                      {[...Array(item.countInStock).keys()].map((x) => (
                        <option key={x + 1} value={x + 1}>
                          {x + 1}
                        </option>
                      ))}
                    </FormControl>
                  </Col>
                  <Col md={2}>
                    <Button
                      type='button'
                      variant='light'
                      onClick={() => removeFromCartHandler(item.product, item.color)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            ))}
          </ListGroup>
          </>
        )}
      </Col>
      <Col md={4}>
        <Card>
          <ListGroup variant='flush'>
            <ListGroupItem>
              <h3>
                Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)})
                items
              </h3>
              &#8377;
              {cartItems
                .reduce((acc, item) => acc + item.qty * item.price, 0)
                .toFixed(2)}
            </ListGroupItem>
            <ListGroupItem>
              <Button
                type='button'
                className='btn-block'
                disabled={cartItems.length === 0}
                onClick={checkoutHandler}
              >
                Proceed to checkout
              </Button>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  )
}
export default CartScreen