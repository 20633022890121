import axios from "axios";
import {
    COUPON_LIST_REQUEST,
    COUPON_LIST_SUCCESS,
    COUPON_LIST_FAIL,
    COUPON_DETAILS_REQUEST,
    COUPON_DETAILS_SUCCESS,
    COUPON_DETAILS_FAIL,
    COUPON_DELETE_REQUEST,
    COUPON_DELETE_SUCCESS,
    COUPON_DELETE_FAIL,
    COUPON_CREATE_REQUEST,
    COUPON_CREATE_SUCCESS,
    COUPON_CREATE_FAIL,
    COUPON_UPDATE_REQUEST,
    COUPON_UPDATE_SUCCESS,
    COUPON_UPDATE_FAIL,
    COUPON_ALL_REQUEST,
    COUPON_ALL_SUCCESS,
    COUPON_ALL_FAIL,
} from "../constants/couponConstants";

export const allCoupons = () => async (dispatch) => {
    try {
        dispatch({ type: COUPON_ALL_REQUEST });

        const { data } = await axios.get("/api/coupons");
        dispatch({
            type: COUPON_ALL_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: COUPON_ALL_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.response,
        });
    }
};

export const listCoupons =
    (keyword = "", pageNumber = "") =>
    async (dispatch) => {
        try {
            dispatch({ type: COUPON_LIST_REQUEST });

            const { data } = await axios.get(
                `/api/coupons/limit?keyword=${keyword}&pageNumber=${pageNumber}`
            );

            dispatch({
                type: COUPON_LIST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: COUPON_LIST_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.response,
            });
        }
    };

export const listCouponDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: COUPON_DETAILS_REQUEST });

        const { data } = await axios.get(`/api/coupons/${id}`);

        dispatch({
            type: COUPON_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: COUPON_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.response,
        });
    }
};

export const deleteCoupon = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: COUPON_DELETE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`/api/coupons/${id}`, config);

        dispatch({
            type: COUPON_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: COUPON_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.response,
        });
    }
};

export const createCoupon = (coupon) => async (dispatch, getState) => {
    try {
        dispatch({ type: COUPON_CREATE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.post(`/api/coupons`, coupon, config);

        dispatch({
            type: COUPON_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: COUPON_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.response,
        });
    }
};

export const updateCoupon = (coupon) => async (dispatch, getState) => {
    try {
        dispatch({ type: COUPON_UPDATE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.put(
            `/api/coupons/${coupon._id}`,
            coupon,
            config
        );

        dispatch({
            type: COUPON_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: COUPON_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.response,
        });
    }
};
