import React, { useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allCategories } from "../actions/categoriesAction";
import Message from "../components/Message";
import Loader from "../components/Loader";

const CategoryHeader = () => {
    const dispatch = useDispatch();

    const categorysAll = useSelector((state) => state.categoryAll);
    const { loading, error, categories } = categorysAll;

    useEffect(() => {
        dispatch(allCategories());
    }, [dispatch]);

    return (
        <header>
            <Navbar
                className="cat-nav"
                bg="light"
                variant="light"
                expand="lg"
                collapseOnSelect
            >
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto cat-nav-items">
                            {loading ? (
                                <Loader />
                            ) : error ? (
                                <Message variant="danger">{error}</Message>
                            ) : (
                                categories.map(category => (
                                  <Nav.Link
                                    key={`link_${category.name}`}
                                    as={Link}
                                    to={`/products/${category.name.toLowerCase()}`}
                                    href={`/products/${category.name.toLowerCase()}`}
                                >
                                    <Navbar.Text className="cat-item">
                                        {category.name}
                                    </Navbar.Text>
                                </Nav.Link>
                                ))
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default CategoryHeader;
