import React from "react";
import { Link } from "react-router-dom";
import Meta from "../components/Meta";

const AboutScreen = () => {
    return (
        <>
            <Meta title="about gehnagali" canonicalLink="/about" />
            <Link to="/" className="btn btn-light">
                Go Back
            </Link>
            <h1>About us</h1>
            <p className="about-p">
                GehnaGali is a brand which brings to you high-quality hand
                crafted traditional & fashionable jewellery. We strongly believe
                in providing high quality fashionable products at
                pocket-friendly prices. At GehnaGali we celebrate your
                individual style, and every design of ours has a beautiful story
                behind it.
                <br />
                Buy the exclusive hand picked range of artificial jewellery
                online from GehnaGali, we have a wide range of earrings,
                necklaces, chokers, which will help ensure that you achieve the
                style you want. So, accessorize, dress and make us a part of
                your style statement.
            </p>
        </>
    );
};

export default AboutScreen;
