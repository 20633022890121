import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "./Loader";

const Modal = ({ children, onClose }) => {
    useEffect(() => {
        const handleEscape = (event) => {
            if (event.keyCode === 27) {
                onClose();
            }
        };

        const handleClickOutside = (event) => {
            if (!event.target.closest(".popup-modal-content")) {
                onClose();
            }
        };

        document.addEventListener("keydown", handleEscape);
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("keydown", handleEscape);
            document.removeEventListener("click", handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className="popup-modal">
            <div className="popup-modal-content">
                <button className="popup-modal-close" onClick={onClose}>
                    &times;
                </button>
                {children}
            </div>
        </div>
    );
};

const Popup = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");

    const handlePopupClose = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        const lastSeenPopupDate = localStorage.getItem("lastSeenPopupDate");
        const mailsend = localStorage.getItem("mailsend");
        const currentDate = new Date();
        if (
            !mailsend &&
            (!lastSeenPopupDate ||
                new Date(lastSeenPopupDate) < currentDate.setHours(0, 0, 0, 0))
        ) {
            setTimeout(() => {
                setShowPopup(true);
                localStorage.setItem("lastSeenPopupDate", currentDate);
            }, 3000); // Add a delay of 3 seconds before showing the popup
        }
    }, []);

    const submitHandler = async(e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
          await axios.post("/api/coupons/sendCouponEmail", { email });
          setEmailSent(true);
          localStorage.setItem("mailsend", true);
        } catch (error) {
          setEmailSent(false);
          localStorage.setItem("mailsend", false);
        }
        setIsLoading(false)
    };

    return (
        <div>
            {showPopup && (
                <Modal onClose={handlePopupClose}>
                    {emailSent ? (
                        <>
                            <div className="coupon-sent-container">
                                <h6 className="coupon-sent-heading">
                                    Hooray! Your Coupon is on Its Way!
                                </h6>
                                <p className="coupon-sent-message">
                                    Check your email for your unique code and
                                    start shopping now!
                                </p>
                                <br/>
                                <button onClick={handlePopupClose}  className="popup-button">
                                    Shop Now
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <h2 className="popup-title">
                                Get 10% Off TODAY ONLY!
                            </h2>
                            <p className="popup-subtitle">
                                Enter your email to get your unique code
                            </p>
                            <form onSubmit={submitHandler}>
                                <input
                                    type="email"
                                    className="popup-input"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                {isLoading?<Loader/>:<button type="submit" className="popup-button">
                                    Get Your Code
                                </button>}
                                
                            </form>
                        </>
                    )}
                </Modal>
            )}
        </div>
    );
};

export default Popup;
