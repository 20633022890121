import React from "react";
import { Link } from "react-router-dom";

const TermsAndConditionsScreen = () => {
    return (
        <>
            <Link to="/" className="btn btn-light">
                Go Back
            </Link>
            <p>TERMS AND CONDITION</p>
            <p>Effective Date:08/October/2022</p>
            <p>Website Covered: Gehnagali.com</p>
            <p>
                <strong>THE AGREEMENT: </strong>The use of this website and
                services on this website provided by Gehna Gali (hereinafter
                referred to as{" "}
                <strong>&lsquo;&rsquo;Owner&rsquo;&rsquo; </strong>are subject
                to the following Terms &amp; Condition (hereinafter the
                &lsquo;&rsquo;<strong>Terms of Service&rsquo;&rsquo;</strong>),
                all parts sub-parts of which are specifically incorporated by
                reference here together with the Privacy Policy. Following are
                the Terms of Service governing your use of gehnagali.com, all
                the pages on the website and any services provided by or on this
                website.<span>&nbsp;</span>
            </p>
            <p>
                By accessing either directly or through a hyperlink, the
                website, and/ or purchasing something from us, engage in our
                &lsquo;&rsquo;service&rsquo;&rsquo; and agree to be bound by the
                Terms of Service including those additional terms and conditions
                and policies referenced herein and/or available by hyperlink.
                These terms of services apply to all users of the site,
                including without limitation vendors, buyers, customers,
                merchants, browsers, and/or contributors of content. You
                acknowledge and agree that the website may use your personal
                information in the manner prescribed in our Privacy Policy which
                sets forth how information collected about you is collected,
                used and stored.<span>&nbsp;</span>
            </p>
            <ul>
                <li>
                    The parties referred to in these Terms of Service shall be
                    defined as follows:
                </li>
            </ul>
            <ul>
                <li>
                    Owner, Us, We: The Owner, as the creator, operator and
                    publisher of the Website, makes the Website and certain
                    services on it, available to users. Gehna Gali, Owner, Us,
                    We, Our, Ours and the first-person pronouns will refer to
                    the Owner, as well as all employees and affiliates to the
                    owner.<span>&nbsp;</span>
                </li>
                <li>
                    You, the User, the Client: You as the user of the of the
                    Website, will be referred throughout these Terms of Service
                    with second person pronoun such as You, Your , Yours or as
                    User or Client. For the Purpose of these Terms of Service,
                    the term &lsquo;&rsquo;User&rsquo;&rsquo; or
                    &lsquo;&rsquo;You&rsquo;&rsquo; shall mean any natural or
                    legal person who is accessing the website. The term &ldquo;
                    <strong>Your&rsquo;&rsquo; </strong>shall be construed
                    accordingly.<span>&nbsp;</span>
                </li>
                <li>
                    Parties: Collectively the parties to these Terms of Service
                    (the Owner and You) will be referred to as Parties.
                    <span>&nbsp;</span>
                </li>
            </ul>
            <p>&nbsp;</p>
            <ul>
                <li>
                    <strong>
                        ASSENT &amp; ACCEPTANCE<span>&nbsp;</span>
                    </strong>
                </li>
            </ul>
            <p>
                By using this website, You warrant that You have read and
                reviewed these Terms of Service and that You are agree to be
                bound by it.<span>&nbsp;</span>If You do not agree to be bound
                by these Terms of Service, please leave the website immediately.
                The Owner only agrees to provide use of this website and
                Services to You if you assent to these Terms and Service.
                Further based on the Services obtained by a User, additional
                terms and conditions in respect of the specific Service may
                apply, which shall be deemed an agreement between the Users and
                the Owner.<span>&nbsp;</span>
            </p>
            <ul>
                <li>
                    <strong>
                        AGE RESTRICTION<span>&nbsp;</span>
                    </strong>
                </li>
            </ul>
            <p>
                You must be at least 18 (eighteen) years of age to use this
                Website, or any services contained herein. By using this
                Website, You represent and warrant that You are at least 18
                years of age and may legally agree to these Terms of Services.
                The Owner assumes no responsibility or liability for any
                misrepresentation of Your age.<span>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li>
                    <strong>
                        ABOUT THIS SITE<span>&nbsp;</span>
                    </strong>
                </li>
            </ul>
            <p>
                The Website is an online store which carries out the sale of
                Jewelry. We reserve the right to refuse service or refuse to
                sell the product on the Website at our sole discretion to anyone
                for any reason at any time.<span>&nbsp;</span>
            </p>
            <p>
                The Website does not screen or censor the users who register on
                and access the Website. You assume all risk associated with
                dealing with other users with whom you come in contact through
                the website. You agree to use the Website only for lawful
                purposes without infringing the rights of or restricting the use
                of Website by any third party.<span>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <ul>
                <li>
                    <strong>LICENSE TO USE WEBSITE</strong>
                </li>
            </ul>
            <p>
                The Owner may provide You with certain information as a result
                of Your use of the Website or Services. Such information may
                include but is not limited to, documentation, data or
                information developed by the Owner, and other material which may
                assist in Your use of the Website or Services. Subject to these
                Terms of Services the Owner grants You a non-exclusive, limited,
                non-transferrable and revocable license to use the Owner
                Material <span>&nbsp; </span>solely with your connection with
                Your use of Website and Services. The Owner Materials may not be
                used for any other purpose and this license terminate upon your
                cessation of use of the Website or Services or at the
                termination of these Terms and Services.<span>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <p>
                You agree not to collect contact information of other Users from
                the Website or download or copy any information by means of
                unsolicited access so as to communicate directly with them or
                for any reason whatsoever.<span>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <p>
                Any unauthorized use by You shall terminate the permission or
                license granted to You by the Website and You agree that You
                shall not bypass any measure used by the Owner to prevent or
                restrict access to the website.<span>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <ul>
                <li>
                    <strong>
                        INTELLECTUAL PROPERTY<span>&nbsp;</span>
                    </strong>
                </li>
            </ul>
            <p>
                You are agreeing that the Website and all Services provided by
                the Owner are the property of the Owner, including all
                copyrights, trademark, trade secret, patents, and other
                individual property, (&lsquo;&rsquo;Owner IP&rsquo;&rsquo;). You
                agree that the Owner owns all the rights, title and interest in
                and to the Owner IP and that you will not use the Owner IP for
                any unlawful and infringing purpose. You agree not to distribute
                or reproduce the Owner IP in way, including electronically or
                via registration of any trademarks, tradenames or service marks
                or Uniform Resource Locator (URLs), without express written
                permission from the Owner.<span>&nbsp;</span>
            </p>
            <ul>
                <li>
                    In order to make the Website and Services available to you
                    You hereby grant the Owner royalty free, non-exclusive,
                    worldwide license to copy, display, use, broadcast, transmit
                    and make derivative works of any content You publish, upload
                    or otherwise make available to the Website. The Owner claims
                    no further property right in Your Content.
                    <span>&nbsp;</span>
                </li>
                <li>
                    If You feel that any of Your intellectual property rights
                    have been infringed or violated by the posting of
                    information or media by another of Our users, please contact
                    Us and let Us know.<span>&nbsp;</span>
                </li>
            </ul>
            <p>&nbsp;</p>
            <ul>
                <li>
                    <strong>USER OBLIGATION</strong>
                </li>
            </ul>
            <p>
                As a user of the Website or Services, You may be asked to
                register with Us. When You do so, You will choose a user
                identifier, which may be Your email address or another term as
                well as password. You may also provide personal information,
                including but not limited to, Your name. You are responsible for
                ensuring the accuracy of this information. This identifying
                information will enable you to use the Website and Services. You
                must not share such identifying information with any third party
                and if You discover that Your identifying information has been
                compromised, You agree to notify us immediately in writing. An
                e-mail notification will suffice. You are responsible for
                maintaining the safety and security for your identifying
                information as well as keeping us apprised of any changes to
                Your identifying information. The billing information You
                provide us including credit card, billing address and any other
                information, is subject to same confidentiality and accuracy
                requirements as the rest of Your identifying information.
                Providing false or inaccurate information, or using Website or
                Service to further fraud or unlawful activity is grounds for
                immediate termination of these Terms of Services. The Owner
                reserves the right to refuse the service, terminate accounts or
                remove or edit the account in its sole discretion.
                <span>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <ul>
                <li>
                    <strong>
                        PAYMENT AND OBLIGATION<span>&nbsp;</span>
                    </strong>
                </li>
            </ul>
            <p>
                Should you register for any of the paid Services. On this
                website or on purchase of any product or service on this
                website. You agree to pay Us the specific monetary amounts
                required for that product or Services. These monetary amounts
                (&lsquo;&rsquo;fees&rsquo;&rsquo;) will be described to You
                during account registration and/or confirmation process. The
                final amount required for payment will be shown to You
                immediately prior to purchase. Payment for any on-going Services
                is billed automatically and You notify Us that you would like to
                terminate Your access to the services.<span>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <p>
                We reserve the right to refuse service or refuse to sell the
                products on the Website at our sole discretion to anyone for any
                reason at any time.<span>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
            <ul>
                <li>
                    <strong>
                        ACCEPTABLE USE<span>&nbsp;</span>
                    </strong>
                </li>
            </ul>
            <p>
                You agree not to use the Website or Services for any unlawful
                purpose or any purpose prohibited under this clause. You agree
                not to use this Website or Service in any way that could damage
                the Website, Service or general business of the Owner.
                <span>&nbsp;</span>
            </p>
            <ul>
                <li>Your further agree not to use the Website or Service:</li>
            </ul>
            <p>
                I)To harass, abuse or threaten others or otherwise violate any
                person&rsquo;s legal rights;
            </p>
            <p>
                II)To violate any intellectual property rights from the Owner or
                any third party.<span>&nbsp;</span>
            </p>
            <p>
                III) To upload or otherwise disseminate any computer viruses or
                other software that may damage the property of the Owner.
            </p>
            <p>IV) To perpetrate any other fraud;</p>
            <p>
                V)To engage in or create any other unlawful gambling, sweets
                cape or <span>&nbsp; </span>pyramid scheme;
            </p>
            <p>VI) To publish or distribute any obscene or material;</p>
            <p>
                VII) To publish or distribute any material that incites
                violence, hate or discrimination towards any group;
            </p>
            <p>VIII) To unlawfully gather information about others.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
                You are prohibited from using the site or its content: (a) for
                any unlawful purpose; (b) to solicit others to perform or
                participate in any unlawful acts; (c) to infringe on any third
                party's intellectual property or proprietary rights, or rights
                of publicity or privacy, whether knowingly or unknowingly; (d)
                to violate any local, federal or international law, statute,
                ordinance or regulation; ((e) to harass, abuse, insult, harm,
                defame, slander, disparage, intimidate, or discriminate based on
                gender, sexual orientation, religion, ethnicity, race, age,
                national origin, or disability; (f) to submit false or
                misleading information or any content which is defamatory,
                libellous, threatening, unlawful, harassing, indecent, abusive,
                obscene, or lewd and lascivious or pornographic, or exploits
                minors in any way or assists in human trafficking or content
                that would violate rights of publicity and/or privacy or that
                would violate any law; (g) to upload or transmit viruses or any
                other type of malicious code that will or may be used in any way
                that will affect the functionality or operation of the Service
                or of any related website, other websites, or the Internet; (h)
                to collect or track the personal information of others; (i) to
                damage, disable, overburden, or impair the Website or any other
                party's use of the Website; (j) to spam, phish, pharm, pretext,
                spider, crawl, or scrape; (j) for any obscene or immoral
                purpose; or (k) to interfere with or circumvent the security
                features of the Service or any related website, other websites,
                or the Internet; (l) to personally threaten or has the effect of
                personally threatening other Users. We reserve the right to
                terminate your use of the Service or any related website for
                violating any of the prohibited uses. The Company has the full
                authority to review all content posted by Users on the Website.
                You acknowledge that the Website is not responsible or liable
                and does not control the content of any information that may be
                posted to the Website by You or other User of the Website and
                you are solely responsible for the same. You agree that You
                shall not upload, post, or transmit any content that you do not
                have a right to make available (such as, the intellectual
                property of another party).
            </p>
            <p>
                You agree to comply with all applicable laws, statutes and
                regulations concerning your use of the Website and further agree
                that you will not transmit any information, data, text, files,
                links, software, chats, communication or other materials that
                are abusive, invasive of another's privacy, harassing,
                defamatory, vulgar, obscene, unlawful, false, misleading,
                harmful, threatening, hateful or racially or otherwise
                objectionable, including without limitation material of any kind
                or nature that encourages conduct that could constitute a
                criminal offence, give rise to civil liability or otherwise
                violate any applicable local, state, provincial, national, or
                international law or regulation, or encourage the use of
                controlled substances.
            </p>
            <p>
                We may, but have no obligation to, monitor, edit or remove
                content that we determine in our sole discretion are unlawful,
                offensive, threatening, libellous, defamatory, pornographic,
                obscene or otherwise objectionable or violates any party's
                intellectual property or these Terms of Service.
            </p>
            <p>
                You may not use our products for any illegal or unauthorized
                purpose nor may you, in the use of the Service, violate any laws
                in your jurisdiction (including but not limited to copyright
                laws).
            </p>
            <p>
                <strong>&nbsp;</strong>
            </p>
            <p>
                <strong>&nbsp;</strong>
            </p>
            <p>&nbsp;</p>
            <p>10) AFFILIATE MARKETING &amp; ADVERTISING</p>
            <p>
                The Owner, through the Website and Services, may engage in
                affiliate marketing whereby the Owner receives a commission on
                or percentage of the sale of goods or services on or through the
                Website. The Owner may also accept advertising and sponsorships
                from commercial businesses or receive other forms of advertising
                compensation.
            </p>
            <p>
                <strong>&nbsp;</strong>
            </p>
            <p>
                <strong>
                    <br />
                </strong>
                11) ASSUMPTION OF RISK
            </p>
            <p>
                The Website and Services are provided for communication purposes
                only. You acknowledge and agree that any information posted on
                Our Website is not intended to be legal advice, medical advice,
                or financial advice, and no fiduciary relationship has been
                created between You and the Owner. You further agree that Your
                purchase of any of the products on the Website is at Your own
                risk. The Owner does not assume responsibility or liability for
                any advice or other information given on the Website.
            </p>
            <p>
                <strong>
                    <br />
                </strong>
                12) SALE OF GOODS/SERVICES
            </p>
            <p>
                The Owner may sell goods or services or allow third parties to
                sell goods or services on the Website. The Owner undertakes to
                be as accurate as possible with all information regarding the
                goods and services, including product descriptions and images.
                However, the Owner does not guarantee the accuracy or
                reliability of any product information and You acknowledge and
                agree that You purchase such products at Your own risk. For
                goods or services sold by others, the Owner assumes no liability
                for any product and cannot make any warranties about the
                merchantability, fitness, quality, safety or legality of these
                products. For any claim You may have against the manufacturer or
                seller of the product, You agree to pursue that claim directly
                with the manufacturer or seller and not with the Owner. You
                hereby release the Owner from any claims related to goods or
                services manufactured or sold by third parties, including any
                and all warranty or product liability claims.
            </p>
            <p>
                We may, in our sole discretion, limit or cancel quantities
                purchased per person, per household or per order. Any orders
                including orders by or under the same customer account, the same
                credit card, and/or orders that use the same billing and/or
                shipping address may be subject to the above. We may restrict
                the sales of products or Services to any person, geographic
                region or jurisdiction at our sole discretion as we may decide
                on a case-by-case basis.
            </p>
            <p>
                We further reserve an absolute right to refuse any order placed
                by any person and to limit or prohibit orders that, in our sole
                judgment, appear to be placed by dealers, resellers or
                distributors. If we cancel your order, we will contact the
                e-mail and/or billing address/phone number provided at the time
                the order was made.
            </p>
            <p>
                We take care to accurately display the colours and images of our
                products but, we cannot guarantee that your computer or phone
                screen's display of any colour will be accurate. We also do not
                provide any warranties that the quality of any products,
                services, information, or other material purchased or obtained
                by you will meet your expectations and standards, or that any
                errors in the Service will be corrected. Prices and descriptions
                of products are subject to change at any time without notice, at
                our sole discretion. We may remove or discontinue any products
                from our site without prior notice. Any offer for any product or
                service made on this site is void where prohibited.
            </p>
            <p>
                We may provide you with access to third-party tools over which
                we neither monitor nor have any control nor input. You
                acknowledge and agree that we provide access to such tools "as
                is" and "as available" without any warranties, representations
                or conditions of any kind and without any endorsement. We shall
                have no liability whatsoever arising from or relating to your
                use of optional third-party tools. Any use by you of optional
                tools offered through the site is entirely at your own risk and
                discretion and you should ensure that you are familiar with and
                approve of the terms on which tools are provided by the relevant
                third-party provider(s).
            </p>
            <p>13) REVERSE ENGINEERING &amp; SECURITY</p>
            <p>You agree not to undertake any of the following actions:</p>
            <p>
                a) Reverse engineer, or attempt to reverse engineer or
                disassemble any code or software from or on the Website or
                Services;
            </p>
            <p>
                b) Violate the security of the Website or Services through any
                unauthorized access, circumvention of encryption or other
                security tools, data mining or interference to any host, user or
                network.
            </p>
            <p>
                <strong>
                    <br />
                </strong>
                14) DATA LOSS
            </p>
            <p>
                The Owner does not accept responsibility for the security of
                Your account or content. You agree that Your use of the Website
                or Services is at Your own risk.
            </p>
            <p>
                <strong>
                    <br />
                </strong>
                15) INDEMNIFICATION
            </p>
            <p>
                You agree to defend and indemnify the Owner and any of its
                affiliates (if applicable) and hold Us harmless against any and
                all legal claims and demands, including reasonable attorney's
                fees, which may arise from or relate to Your use or misuse of
                the Website or Services, Your breach of these Terms of Service,
                or Your conduct or actions. You agree that the Owner shall be
                able to select its own legal counsel and may participate in its
                own defence if the Owner wishes.
            </p>
            <p>
                <strong>
                    <br />
                </strong>
                16) SPAM POLICY
            </p>
            <p>
                You are strictly prohibited from using the Website or any of the
                Owner's Services for illegal spam activities, including
                gathering email addresses and personal information from others
                or sending any mass commercial emails.
            </p>
            <p>&nbsp;</p>
            <p>
                <strong>
                    <br />
                </strong>
                17) THIRD-PARTY LINKS &amp; CONTENT
            </p>
            <p>
                The Owner may occasionally post links to third-party websites or
                other services. You agree that the Owner is not responsible or
                liable for any loss or damage caused as a result of Your use of
                any third party services linked to from Our Website.
            </p>
            <p>
                <strong>
                    <br />
                </strong>
                18) MODIFICATION &amp; VARIATION
            </p>
            <p>
                The Owner may, from time to time and at any time without notice
                to You, modify these Terms of Service. You agree that the Owner
                has the right to modify these Terms of Service or revise
                anything contained herein. You further agree that all
                modifications to these Terms of Service are in full force and
                effect immediately upon posting on the Website and that
                modifications or variations will replace any prior version of
                these Terms of Service unless prior versions are specifically
                referred to or incorporated into the latest modification or
                variation of these Terms of Service.
            </p>
            <p>
                a) To the extent any part or sub-part of these Terms of Service
                is held ineffective or invalid by any court of law, You agree
                that the prior, effective version of these Terms of Service
                shall be considered enforceable and valid to the fullest extent.
            </p>
            <p>
                b) You agree to routinely monitor these Terms of Service and
                refer to the Effective Date posted at the top of these Terms of
                Service to note modifications or variations. You further agree
                to clear Your cache when doing so to avoid accessing a prior
                version of these Terms of Service. You agree that Your continued
                use of the Website after any modifications to these Terms of
                Service is a manifestation of Your continued assent to these
                Terms of Service.
            </p>
            <p>
                c) In the event that You fail to monitor any modifications to or
                variations of these Terms of Service, You agree that such
                failure shall be considered an affirmative waiver of Your right
                to review the modified Agreement.
            </p>
            <p>
                <strong>
                    <br />
                </strong>
                19) ENTIRE AGREEMENT
            </p>
            <p>
                This Agreement constitutes the entire understanding between the
                Parties with respect to any and all use of this Website. This
                Agreement supersedes and replaces all prior or contemporaneous
                agreements or understandings, written or oral, regarding the use
                of this Website.
            </p>
            <p>
                <strong>
                    <br />
                </strong>
                20) SERVICE INTERRUPTIONS
            </p>
            <p>
                The Owner may need to interrupt Your access to the Website to
                perform maintenance or emergency services on a scheduled or
                unscheduled basis. You agree that Your access to the Website may
                be affected by unanticipated or unscheduled downtime, for any
                reason, but that the Owner shall have no liability for any
                damage or loss caused as a result of such downtime.
            </p>
            <p>
                <strong>&nbsp;</strong>
            </p>
            <p>21) TERM, TERMINATION &amp; SUSPENSION</p>
            <p>
                The Owner may terminate these Terms of Service with You at any
                time for any reason, with or without cause. The Owner
                specifically reserves the right to terminate these Terms of
                Service if You violate any of the terms outlined herein,
                including, but not limited to, violating the intellectual
                property rights of the Owner or a third party, failing to comply
                with applicable laws or other legal obligations, and/or
                publishing or distributing illegal material. If You have
                registered for an account with Us, You may also terminate these
                Terms of Service at any time by contacting Us and requesting
                termination. Please keep in mind that any outstanding fees will
                still be due even after termination of Your account. At the
                termination of these Terms of Service, any provisions that would
                be expected to survive termination by their nature shall remain
                in full force and effect.
            </p>
            <p>
                <strong>
                    <br />
                </strong>
                22) NO WARRANTIES
            </p>
            <p>
                You agree that Your use of the Website and Services is at Your
                sole and exclusive risk and that any Services provided by Us are
                on an "As Is" basis. The Owner hereby expressly disclaims any
                and all express or implied warranties of any kind, including,
                but not limited to the implied warranty of fitness for a
                particular purpose and the implied warranty of merchantability.
                The Owner makes no warranties that the Website or Services will
                meet Your needs or that the Website or Services will be
                uninterrupted, error-free, or secure. The Owner also makes no
                warranties as to the reliability or accuracy of any information
                on the Website or obtained through the Services. You agree that
                any damage that may occur to You, through Your computer system,
                or as a result of the loss of Your data from Your use of the
                Website or Services is Your sole responsibility and that the
                Owner is not liable for any such damage or loss.
            </p>
            <p>
                All information, software, products, services and related
                graphics are provided on this site is "as is" and "as available"
                basis with without warranty of any kind, either expressed or
                implied. The Website disclaims all warranties, expressed or
                implied including, without limitation, all implied warranties of
                merchantability, fitness for a particular purpose, title and
                non-infringement or arising from a course of dealing, usage, or
                trade practice. The company makes no representation about the
                suitability of the information, software, products, and services
                contained on this Website for any purpose, and the inclusion or
                offering of any products or services on this Website does not
                constitute any endorsement or recommendation of such products or
                services.
            </p>
            <p>
                The Website makes no warranty that the use of the Website will
                be uninterrupted, timely, secure, without defect or error-free.
                You expressly agree that use of the site is at your own risk.
                The Website shall not be responsible for any content found on
                the Website.
            </p>
            <p>
                Your use of any information or materials on this site or
                otherwise obtained through use of this Website is entirely at
                your own risk, for which we shall not be liable. It shall be
                your own responsibility to ensure that any products, services or
                information available through this website meet your specific
                requirements.
            </p>
            <p>
                The Website assumes no responsibility for the accuracy,
                currency, completeness or usefulness of information, views,
                opinions, or advice in any material contained on the Website.
                Any information from third parties or advertisers is made
                available without making any changes and so the Website cannot
                guarantee accuracy and is not liable for any inconsistencies
                arising thereof. All postings, messages, advertisements, photos,
                sounds, images, text, files, video, or other materials posted
                on, transmitted through, or linked from the Website, are solely
                the responsibility of the person from whom such Content
                originated, and the Website does not control and is not
                responsible for Content available on the Website.
            </p>
            <p>
                There may be instances when incorrect information is published
                inadvertently on our Website or in the Service such as
                typographical errors, inaccuracies or omissions that may relate
                to product descriptions, pricing, promotions, offers, product
                shipping charges, transit times and availability. Any errors,
                inaccuracies, or omissions may be corrected at our discretion at
                any time, and we may change or update information or cancel
                orders if any information in the Service or on any related
                website is inaccurate at any time without prior notice
                (including after you have submitted your order).
            </p>
            <p>
                We undertake no obligation to update, amend or clarify
                information in the Service or on any related website, including
                without limitation, pricing information, except as required by
                law. No specified update or refresh date applied in the Service
                or on any related website should be taken to indicate that all
                information in the Service or on any related website has been
                modified or updated.
            </p>
            <p>
                The Website shall not be responsible for any interaction between
                you and the other users of the Website. Under no circumstances
                will the Website be liable for any goods, services, resources,
                or content available through such third-party dealings or
                communications, or for any harm related thereto. The Website is
                under no obligation to become involved in any disputes between
                you and other users of the Website or between you and any other
                third parties. You agree to release the Website from any and all
                claims, demands, and damages arising out of or in connection
                with such dispute.
            </p>
            <p>
                You agree and understand that while the Website has made
                reasonable efforts to safeguard the Website, it cannot and does
                not ensure or make any representations that the Website or any
                of the information provided by You cannot be hacked by any
                unauthorised third parties. You specifically agree that the
                Website shall not be responsible for unauthorized access to or
                alteration of Your transmissions or data, any material or data
                sent or received or not sent or received, or any transactions
                entered into through the Website.
            </p>
            <p>
                You hereby agree and confirm that the Website shall not be held
                liable or responsible in any manner whatsoever for such hacking
                or any loss or damages suffered by you due to unauthorized
                access of the Website by third parties or for any such use of
                the information provided by You or any spam messages or
                information that You may receive from any such unauthorised
                third party (including those which are although sent
                representing the name of the Website but have not been
                authorized by the Website) which is in violation or
                contravention of this Terms of Service or the Privacy Policy.
            </p>
            <p>
                You specifically agree that the Website is not responsible or
                liable for any threatening, defamatory, obscene, offensive, or
                illegal content or conduct of any other party or any
                infringement of another's rights, including intellectual
                property rights. You specifically agree that the Website is not
                responsible for any content sent using and/or included on the
                Website by any third party.
            </p>
            <p>
                The Website has no liability and will make no refund in the
                event of any delay, cancellation, strike, force majeure, or
                other causes beyond their direct control, and they have no
                responsibility for any additional expense omissions delays or
                acts of any government or authority.
            </p>
            <p>
                You will be solely responsible for any damage to your computer
                system or loss of data that results from the download of any
                information and/or material. Some jurisdictions do not allow the
                exclusion of certain warranties, so some of the above exclusions
                may not apply to you.
            </p>
            <p>
                In no event shall the Website be liable for any direct,
                indirect, punitive, incidental, special, consequential damages
                or any damages whatsoever including, without limitation, damages
                for loss of use, data or profits, arising out of or in any way
                connected with the use or performance of the site, with the
                delay or inability to use the site or related services, the
                provision of or failure to provide Services, or to deliver the
                products or for any information, software, products, services
                and related graphics obtained through the site, or any
                interaction between you and other participants of the Website or
                otherwise arising out of the use of the Website, damages
                resulting from use of or reliance on the information present,
                whether based on contract, tort, negligence, strict liability or
                otherwise, even if the Website or any of its
                affiliates/suppliers has been advised of the possibility of
                damages. If despite the limitation above, the Company is found
                liable for any loss or damage which arises out of or in any way
                connected with the use of the Website and/ or provision of
                Services, then the liability of the Company will in no event
                exceed, 50% (Fifty percent) of the amount you paid to the
                Company in connection with such transaction(s) on this Website.
            </p>
            <p>
                You accept all responsibility for and hereby agree to indemnify
                and hold harmless the company from and against, any actions
                taken by you or by any person authorized to use your account,
                including without limitation, disclosure of passwords to third
                parties. By using the Website, you agree to defend, indemnify,
                and hold harmless the indemnified parties from any and all
                liability regarding your use of the site or participation in any
                site's activities. If you are dissatisfied with the Website, or
                the Services or any portion thereof, or do not agree with these
                terms, your only recourse and exclusive remedy shall be to stop
                using the site.
            </p>
            <p>
                <strong>
                    <br />
                </strong>
                23) LIMITATION ON LIABILITY
            </p>
            <p>
                The Owner is not liable for any damage that may occur to You as
                a result of Your use of the Website or Services, to the fullest
                extent permitted by law.<span>&nbsp;</span>
            </p>
            <p>&nbsp;</p>
        </>
    );
};

export default TermsAndConditionsScreen;
