import React from 'react'
import { Helmet } from 'react-helmet-async'
const Meta = ({ title, description, canonicalLink }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <link rel='canonical' href={canonicalLink} />
    </Helmet>
  )
}

Meta.defaultProps = {
  title: 'Gehna Gali',
  description: `GehnaGali is a brand which brings to you high-quality hand crafted traditional & fashionable jewellery at pocket-friendly prices.`,
  canonicalLink:
    '/',
}

export default Meta
