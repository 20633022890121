import {
  CART_ADD_ITEM,
  CART_REMOVED_ITEM,
  CART_CLEAR_ITEMS,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SALE_VALIDITY
} from '../constants/cartConstants'

export const cartReducer = (
  state = { cartItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload

      const itemExist = state.cartItems.find((x) => (x.product === item.product && x.color === item.color &&item.specialDiscoutedProduct===x.specialDiscoutedProduct))
      if (itemExist) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.product === item.product && x.color === item.color ? item : x
          ),
        }
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        }
      }
    case CART_REMOVED_ITEM:

      return {
        ...state,
        cartItems: state.cartItems.filter((x) => !((x.product === action.payload.id) && (x.color === action.payload.color) && (action.payload.specialDiscoutedProduct===x.specialDiscoutedProduct))),
      }
    case CART_CLEAR_ITEMS:
      return {
        ...state,
        cartItems: []
      }
    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      }
    case CART_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      }
      case CART_SALE_VALIDITY:
      return {
        ...state,
        product_0: action.payload,
      }
    default:
      return state
  }
}
