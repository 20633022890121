import React, { useState, useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  Form,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listCouponDetails, updateCoupon } from '../actions/couponActions'
import { COUPON_UPDATE_RESET } from '../constants/couponConstants'
import { allCategories } from '../actions/categoriesAction'

const CouponEditScreen = () => {
  const history = useHistory()
  const { id } = useParams()

  const [name, setName] = useState('')
  const [discount, setDiscount] = useState(0);
  const [quantity, setQuantity] = useState();
  const [expiryDate, setExpiryDate] = useState(new Date().toISOString().slice(0, 10));
  const [minimumPurchase, setMinimumPurchase] = useState(0);
  const [categories, setCategories] = useState([]);
  const [product, setProduct] = useState("");
  const [emails, setEmails] = useState("");
  const [contact, setContact] = useState("");
  const [isActive, setIsActive] = useState(false)

  const dispatch = useDispatch()

  const couponDetails = useSelector((state) => state.couponDetails)
  const { loading, error, coupon } = couponDetails

  const categorysAll = useSelector((state) => state.categoryAll);
  const { loading:loadingCategories, categories:productCategories } = categorysAll

  const couponUpdate = useSelector((state) => state.couponUpdate)
  const {
    loading: loadingUpdate,
    error: errrorUpdate,
    success: successUpdate,
  } = couponUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: COUPON_UPDATE_RESET })
      history.push('/admin/couponlist')
    } else {
      if (!coupon.name || coupon._id !== id) {
        dispatch(listCouponDetails(id))
      } else {
        setName(coupon.name)
        setDiscount(coupon.discount)
        setQuantity(coupon.quantity)
        setExpiryDate(new Date(coupon.expiryDate).toISOString().slice(0, 10))
        setMinimumPurchase(coupon.minimumPurchase)
        setCategories(coupon.categories)
        setProduct(coupon.product)
        setEmails(coupon.emails)
        setContact(coupon.contact)
        setIsActive(coupon.isActive)
      }
    }
  }, [coupon, id, dispatch, successUpdate, history])

  useEffect(() => {
    dispatch(allCategories());
  }, [dispatch]);

  const handleSelectCategories = (selectedOptions) => {
    if(selectedOptions.length===0){
      setCategories([])
      return
    }
      const tempCategories = [];
      if(selectedOptions[0].value==="All"){
        setCategories(productCategories.map(category => category.name))
      }else{
        for (let i=0; i<selectedOptions.length; i++) {
          tempCategories.push(selectedOptions[i].value);
        }
        tempCategories.filter(category => category !== "All")
        setCategories(tempCategories);
      }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateCoupon({
        _id: id,
        name,
        discount,
        quantity,
        expiryDate,
        minimumPurchase,
        categories,
        product,
        emails,
        contact,
        isActive
      })
    )
  }
  return (
    <>
      <Link to='/admin/couponList' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Coupon</h1>
        {(loadingUpdate || loadingCategories) && <Loader />}
        {errrorUpdate && <Message variant='danger'>{errrorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <FormGroup controlId='name'>
              <FormLabel>Name</FormLabel>
              <FormControl
                type='text'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              ></FormControl>
            </FormGroup>
            <FormGroup controlId='discount'>
              <FormLabel>Discount</FormLabel>
              <FormControl
                type='number'
                placeholder='Enter Discount Percentage Number'
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                required
              ></FormControl>
            </FormGroup>
            <FormGroup controlId='quantity'>
              <FormLabel>Quantity</FormLabel>
              <FormControl
                type='number'
                placeholder='leane blank for no limit'
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              ></FormControl>
            </FormGroup>
            <FormGroup controlId='expiryDate'>
              <FormLabel>Expiry Date</FormLabel>
              <FormControl
                type='date'
                placeholder='Expiry Date'
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                required
              ></FormControl>
            </FormGroup>
            <FormGroup controlId='minimumPurchase'>
              <FormLabel>Minimum Purchase Amount</FormLabel>
              <FormControl
                type='number'
                placeholder='Enter minimum purchase'
                value={minimumPurchase}
                onChange={(e) => setMinimumPurchase(e.target.value)}
                required
              ></FormControl>
            </FormGroup>
            <FormGroup controlId='categories'>
              <FormLabel>Valid Categories</FormLabel>
              <Form.Control
                type="text"
                placeholder="Select Category below"
                defaultValue={categories.join(", ")}
                readOnly
              />
              <Form.Control
                    as="select"
                    value={categories}
                    multiple={true} 
                    onChange={(e)=> {handleSelectCategories(e.target.selectedOptions)}}
                    required
                >
                  <option value={"All"}>
                            {"All"}
                        </option>
                    {productCategories?.map((category) => (
                        <option value={category.name} key={category.name}>
                            {category.name}
                        </option>
                    ))}
                </Form.Control>
                <Form.Text muted> hold ctrl or command for multiple select</Form.Text>
            </FormGroup>
            <FormGroup controlId='product'>
              <FormLabel>Valid Products</FormLabel>
              <FormControl
                type='text'
                placeholder='ex: productId1, productId2....'
                value={product}
                onChange={(e) => setProduct(e.target.value)}
              ></FormControl>
              <Form.Text muted>Leave Blank to enable for all products under selected categories</Form.Text>
            </FormGroup>
            <FormGroup controlId='emails'>
              <FormLabel>Emails</FormLabel>
              <FormControl
                type='text'
                placeholder='ex: email1@email.com, email2@email.com...'
                value={emails}
                onChange={(e) => setEmails(e.target.value)}
              ></FormControl>
              <Form.Text muted>Leave Blank to enable for all</Form.Text>
            </FormGroup>
            <FormGroup controlId='contacts'>
              <FormLabel>Contacts</FormLabel>
              <FormControl
                type='text'
                placeholder='ex: 9876543210, 8976544321...'
                value={contact}
                onChange={(e) => setContact(e.target.value)}
              ></FormControl>
              <Form.Text muted>Leave Blank to enable for all</Form.Text>
            </FormGroup>

            <FormGroup controlId='isActive'>
              <FormLabel>Is Active</FormLabel>
              <FormControl
                type='checkbox'
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              ></FormControl>
            </FormGroup>

            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default CouponEditScreen
