import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  Form,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listBannerDetails, updateBanner } from '../actions/bannerActions'
import { BANNER_UPDATE_RESET } from '../constants/bannerConstants'

const BannerEditScreen = () => {
  const history = useHistory()
  const { id } = useParams()

  const [name, setName] = useState('')
  const [bannerLink, setBannerLink] = useState('')
  const [image, setImage] = useState('')
  const [showBanner, setShowBanner] = useState(false)
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  const bannerDetails = useSelector((state) => state.bannerDetails)
  const { loading, error, banner } = bannerDetails

  const bannerUpdate = useSelector((state) => state.bannerUpdate)
  const {
    loading: loadingUpdate,
    error: errrorUpdate,
    success: successUpdate,
  } = bannerUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: BANNER_UPDATE_RESET })
      history.push('/admin/bannerlist')
    } else {
      if (!banner.name || banner._id !== id) {
        dispatch(listBannerDetails(id))
      } else {
        setName(banner.name)
        setImage(banner.banner)
        setBannerLink(banner.bannerLink)
        setShowBanner(banner.showBanner)
      }
    }
  }, [banner, id, dispatch, successUpdate, history])

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)
      setImage(data.urlList)
      setUploading(false)
    } catch (error) {
      console.log(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateBanner({
        _id: id,
        name,
        banner: image,
        bannerLink,
        showBanner,
      })
    )
  }
  return (
    <>
      <Link to='/admin/bannerList' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Banner</h1>
        {loadingUpdate && <Loader />}
        {errrorUpdate && <Message variant='danger'>{errrorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <FormGroup controlId='name'>
              <FormLabel>Name</FormLabel>
              <FormControl
                type='text'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></FormControl>
            </FormGroup>
            <FormGroup controlId='bannerLink'>
              <FormLabel>Link</FormLabel>
              <FormControl
                type='text'
                placeholder='Leave blank if no link.'
                value={bannerLink}
                onChange={(e) => setBannerLink(e.target.value)}
              ></FormControl>
            </FormGroup>
            <FormGroup controlId='image'>
              <FormLabel>banner</FormLabel>
              <FormControl
                type='text'
                placeholder='Enter image url'
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></FormControl>
              <Form.File
                id='image-file'
                label='Choose File'
                custom
                onChange={uploadFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </FormGroup>

            <FormGroup controlId='description'>
              <FormLabel>Show Banner</FormLabel>
              <FormControl
                type='checkbox'
                checked={showBanner}
                onChange={(e) => setShowBanner(e.target.checked)}
              ></FormControl>
            </FormGroup>

            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default BannerEditScreen
