import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Form,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {  createBanner } from '../actions/bannerActions'
import { BANNER_CREATE_RESET } from '../constants/bannerConstants'

const BannerEditScreen = () => {
  const history = useHistory()

  const [name, setName] = useState('')
  const [bannerLink, setBannerLink] = useState('')
  const [image, setImage] = useState('')
  const [showBanner, setShowBanner] = useState(false)
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  const bannerCreate = useSelector((state) => state.bannerCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
} = bannerCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: BANNER_CREATE_RESET });

    if (!userInfo || !userInfo.isAdmin) {
        history.push("/login");
    }
    if (successCreate) {
        history.push("/admin/bannerlist");
    }
}, [dispatch, history, userInfo, successCreate]);

  

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)
      setImage(data.urlList)
      setUploading(false)
    } catch (error) {
      console.log(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
        createBanner({
        name,
        banner: image,
        bannerLink,
        showBanner,
      })
    )
  }
  return (
    <>
      <Link to='/admin/bannerList' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Create Banner</h1>
  
        {loadingCreate ? (
          <Loader />
        ) : errorCreate ? (
          <Message variant='danger'>{errorCreate}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <FormGroup controlId='name'>
              <FormLabel>Name</FormLabel>
              <FormControl
                type='text'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></FormControl>
            </FormGroup>
            <FormGroup controlId='bannerLink'>
              <FormLabel>Link</FormLabel>
              <FormControl
                type='text'
                placeholder='Leave blank if no link.'
                value={bannerLink}
                onChange={(e) => setBannerLink(e.target.value)}
              ></FormControl>
            </FormGroup>
            <FormGroup controlId='image'>
              <FormLabel>Image</FormLabel>
              <FormControl
                type='text'
                placeholder='Enter image url'
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></FormControl>
              <Form.File
                id='image-file'
                label='Choose File'
                custom
                onChange={uploadFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </FormGroup>

            <FormGroup controlId='description'>
              <FormLabel>Show Banner</FormLabel>
              <FormControl
                type='checkbox'
                checked={showBanner}
                onChange={(e) => setShowBanner(e.target.checked)}
              ></FormControl>
            </FormGroup>

            <Button type='submit' variant='primary'>
              Create
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default BannerEditScreen
