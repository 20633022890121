import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    Row,
    Col,
    Image,
    ListGroup,
    ListGroupItem,
    Card,
    Button,
    FormControl,
    // Form,
    // FormGroup,
    // FormLabel,
    Carousel,
} from "react-bootstrap";
// import Rating from "../components/Rating";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
    listProductDetails,
    // createProductReview,
    allProducts,
} from "../actions/productActions";
// import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";
import Meta from "../components/Meta";

const ProductScreen = () => {
    const { id } = useParams();
    const history = useHistory();
    const [qty, setQty] = useState(1);
    // const [rating, setRating] = useState(0);
    // const [comment, setComment] = useState("");
    const [selectedVariant, setSelectedVariant] = useState();

    const dispatch = useDispatch();
    const productDetails = useSelector((state) => state.productDetails);
    const { loading, product, error } = productDetails;
    product.productVariants = product.productVariants?.filter(product => product.showVarient)
    // const productReviewCreate = useSelector(
    //     (state) => state.productReviewCreate
    // );
    // const { success: successProductReview, error: errorProductReview } =
    //     productReviewCreate;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const productAll = useSelector((state) => state.productAll);
    const {
        loading: loadingSimilarProduct,
        products,
    } = productAll;
    const similarProducts =
        products &&
        product &&
        products.filter(
            (eachProduct) =>
                (eachProduct.category?.toLowerCase() ===
                product.category?.toLowerCase() && eachProduct.showProduct)
        );
    useEffect(() => {
        // if (successProductReview) {
        //     alert("Review Submitted!");
        //     setRating(0);
        //     setComment("");
        //     dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
        // }
        dispatch(listProductDetails(id));
        dispatch(allProducts());
    }, [dispatch, id]);

    useEffect(() => {
        setSelectedVariant(
            product.productVariants && product.productVariants.filter(product => product.showVarient)[0] 
        );
    }, [product]);

    const addToCartHandler = () => {
        history.push(`/cart/${id}?qty=${qty}&color=${selectedVariant.color}`);
    };

    // const submitHandler = (e) => {
    //     e.preventDefault();
    //     dispatch(createProductReview(id, { rating, comment }));
    // };
    const variantClickHander = (variant) => {
        setSelectedVariant(variant);
    };

    const carouselRef = useRef(null);

    const scrollCarousel = (scrollOffset, ref) => {
        ref.current.scrollBy({
            left: scrollOffset,
            behavior: "smooth",
        });
    };

    return (
        <>
            {/* <Link className="btn btn-light my-3" to="/">
                Go Back
            </Link> */}
            {loading ? (
                <Loader />
            ) : error ? (
                <Message variant="danger">{error}</Message>
            ) : (
                <>
                    <Meta
                            title={product.name}
                            description={product.description}
                            canonicalLink="/product/:id"
                        />
                        <Row>
                            <Col md={6} className="product-image-column">
                                <Carousel
                                    pause="hover"
                                    controls={
                                        selectedVariant?.image.split(",")
                                            .length > 1
                                    }
                                    style={{ margin: "-35px" }}
                                    prevIcon={<span className="carousel-control-prev-icon" />}
                                    nextIcon={<span className="carousel-control-next-icon" />}
                                    className="custom-carousel"


                                >
                                    {selectedVariant?.image
                                        .split(",")
                                        .map((image, i) => {
                                            return (
                                                <Carousel.Item
                                                    key={`${image}_${i}`}
                                                >
                                                    <Image
                                                        src={image}
                                                        alt={product.name}
                                                        fluid
                                                        className="d-block carousel-product-image h-auto rounded-0"
                                                    />
                                                </Carousel.Item>
                                            );
                                        })}
                                </Carousel>
                            </Col>
                            <Col md={3}>
                                <ListGroup variant="flush">
                                    <ListGroupItem>
                                        <h2>
                                            {`${product.name}${
                                                product.productVariants &&
                                                product.productVariants.length >
                                                    1
                                                    ? ` (${selectedVariant?.color})`
                                                    : ""
                                            }`}
                                        </h2>
                                    </ListGroupItem>
                                    {/* <ListGroupItem>
                                        <Rating
                                            value={product.rating}
                                            text={`${product.numReviews} reviews`}
                                        />
                                    </ListGroupItem> */}
                                    <ListGroupItem>
                                        Price: &#8377;{selectedVariant?.price}
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        Description: {product.description}
                                    </ListGroupItem>
                                </ListGroup>
                                <hr />
                                {product?.productVariants?.length > 1 && (
                                    <h5>Color: </h5>
                                )}
                                {product?.productVariants?.length > 1 &&
                                    product.productVariants.map((variant) => (
                                        <img
                                            key={variant.color}
                                            src={variant.image.split(",")[0]}
                                            onClick={() =>
                                                variantClickHander(variant)
                                            }
                                            className={
                                                variant.color !==
                                                selectedVariant?.color
                                                    ? "w-25 product-variant-images"
                                                    : "w-25 product-variant-images product-variant-image-selected"
                                            }
                                            alt={variant.color}
                                        />
                                    ))}
                            </Col>
                            <Col md={3}>
                                <Card>
                                    <ListGroup variant="flush">
                                        <ListGroupItem>
                                            <Row>
                                                <Col>Price:</Col>
                                                <Col>
                                                    <strong>
                                                        &#8377;
                                                        {selectedVariant?.price}
                                                    </strong>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <Row>
                                                <Col>Status:</Col>
                                                <Col>
                                                    <strong>
                                                        {selectedVariant?.countInStock >
                                                        0
                                                            ? "In stock"
                                                            : "Out of stock"}
                                                    </strong>
                                                </Col>
                                            </Row>
                                        </ListGroupItem>
                                        {selectedVariant?.countInStock > 0 && (
                                            <ListGroupItem>
                                                <Row>
                                                    <Col>Qty</Col>
                                                    <Col>
                                                        <FormControl
                                                            className="my-form"
                                                            as="select"
                                                            value={qty}
                                                            onChange={(e) =>
                                                                setQty(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            {[
                                                                ...Array(
                                                                    selectedVariant?.countInStock
                                                                ).keys(),
                                                            ].map((x) => (
                                                                <option
                                                                    key={x + 1}
                                                                    value={
                                                                        x + 1
                                                                    }
                                                                >
                                                                    {x + 1}
                                                                </option>
                                                            ))}
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                        )}
                                        <ListGroupItem>
                                            <Button
                                                onClick={addToCartHandler}
                                                className="btn-block"
                                                type="button"
                                                disabled={
                                                    selectedVariant?.countInStock <=
                                                    0
                                                }
                                            >
                                                {userInfo &&
                                                selectedVariant?.countInStock <=
                                                    0
                                                    ? "Out of Stock"
                                                    : "ADD TO CART"}
                                            </Button>
                                        </ListGroupItem>
                                    </ListGroup>
                                </Card>
                            </Col>
                        </Row>
                    {/* <hr/> */}
                    <br />
                    {loadingSimilarProduct ? (
                        <Loader />
                    ) : (
                        <div className="product-carousel">
                            <h2>Similar Products</h2>
                            <div
                                className="carousel-container"
                                ref={carouselRef}
                            >
                                {similarProducts.map((similarProduct) => (
                                    <div
                                        key={similarProduct._id}
                                        className="carousel-item1"
                                    >
                                        <Link
                                            to={`/product/${similarProduct._id}`}
                                        >
                                            <Card className="my-3 p-3 rounded carousel-container-1">
                                                <Card.Img
                                                    src={
                                                        similarProduct
                                                            .productVariants[0]
                                                            .image?.split(",")?.[0]
                                                    }
                                                    alt={similarProduct.name}
                                                    variant="top"
                                                    className="carousel-image"
                                                />
                                                <Card.Body>
                                                    <Card.Title as="div" className="carousel-title">
                                                        <strong>
                                                            {
                                                                similarProduct.name
                                                            }
                                                        </strong>
                                                    </Card.Title>
                                                    {similarProduct.productVariants[0].discount > 0 ? (
                        <Card.Text as="h3" className="card_text_div">
                            <span className="original_price">
                                &#8377;{similarProduct.productVariants[0].originalPrice}
                            </span>
                            <span className="sale_price">
                                &#8377;{similarProduct.productVariants[0].price}
                            </span>
                        </Card.Text>
                    ) : (
                        <Card.Text as="h3" className="card_text_div">
                            &#8377;{similarProduct.productVariants[0].price}
                        </Card.Text>
                    )}
                                                    {/* Add any additional information you want to display */}
                                                </Card.Body>
                                            </Card>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                            <button
                                className="carousel-prev"
                                onClick={() =>
                                    scrollCarousel(-200, carouselRef)
                                }
                            >
                                <span className="arrow">&#8592;</span>
                            </button>
                            <button
                                className="carousel-next"
                                onClick={() => scrollCarousel(200, carouselRef)}
                            >
                                <span className="arrow">&#8594;</span>
                            </button>
                        </div>
                    )}
                    <hr /> <br />
                    {/* <Row>
                        <Col md={10}>
                            <h2>Reviews</h2>
                            {product.reviews.length === 0 && (
                                <Message>No Reviews</Message>
                            )}
                            <ListGroup variant="flush">
                                {product.reviews.map((review) => (
                                    <ListGroupItem key={review._id}>
                                        <strong>{review.name}</strong>
                                        <Rating value={review.rating} />
                                        <p>
                                            {review.createdAt.substring(0, 10)}
                                        </p>
                                        <p>{review.comment}</p>
                                    </ListGroupItem>
                                ))}
                                <ListGroupItem>
                                    <h2>Write a Review</h2>
                                    {errorProductReview && (
                                        <Message variant="danger">
                                            {errorProductReview}
                                        </Message>
                                    )}
                                    {userInfo ? (
                                        <Form onSubmit={submitHandler}>
                                            <FormGroup controlId="rating">
                                                <FormLabel>Rating</FormLabel>
                                                <FormControl
                                                    as="select"
                                                    value={rating}
                                                    onChange={(e) =>
                                                        setRating(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">
                                                        Select...
                                                    </option>
                                                    <option value="1">
                                                        1 - Poor
                                                    </option>
                                                    <option value="2">
                                                        2 - Fair
                                                    </option>
                                                    <option value="3">
                                                        3 - Good
                                                    </option>
                                                    <option value="4">
                                                        4 - Very Good
                                                    </option>
                                                    <option value="5">
                                                        5 - Excellent
                                                    </option>
                                                </FormControl>
                                            </FormGroup>
                                            <FormGroup controlId="comment">
                                                <FormLabel>Comment</FormLabel>
                                                <FormControl
                                                    as="textarea"
                                                    row="3"
                                                    value={comment}
                                                    onChange={(e) =>
                                                        setComment(
                                                            e.target.value
                                                        )
                                                    }
                                                ></FormControl>
                                            </FormGroup>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                            >
                                                Submit
                                            </Button>
                                        </Form>
                                    ) : (
                                        <Message>
                                            Please{" "}
                                            <Link
                                                to="/login"
                                                className="link-color"
                                            >
                                                sign in
                                            </Link>{" "}
                                            to write a review
                                        </Message>
                                    )}
                                </ListGroupItem>
                            </ListGroup>
                        </Col>
                    </Row> */}
                </>
            )}
        </>
    );
};
export default ProductScreen;
