import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicyScreen = () => {
    return (
        <>
            <Link to="/" className="btn btn-light">
                Go Back
            </Link>
            <p >
                <span>&nbsp;</span>PRIVACY POLICY
                <span>&nbsp;</span>
            </p>
            <p >
                Through Your Use of the Website and Services, You may provide Us
                with certain information. By using the Website or the Services,
                You authorize the Owner to use Your information in India and any
                other country where We may operate.
            </p>
            <p >
                a) Information We May Collect or Receive: When You register for
                an account, You provide Us with a valid email address and may
                provide Us with additional information, such as Your name or
                billing information. Depending on how You use Our Website or
                Services, We may also receive information from external
                applications You use to access Our Website, or We may receive
                information through various web technologies, such as cookies,
                log files, clear gifs, web beacons or others.
            </p>
            <p >
                b) How We Use Information: We use the information gathered from
                You to ensure Your continued good experience on Our website,
                including through email communication. We may also track certain
                of the passive information received to improve Our marketing and
                analytics, and for this, We may work with third-party providers.
            </p>
            <p >
                c) How You Can Protect Your Information: If You would like to
                disable Our access to any passive information We receive from
                the use of various technologies, You may choose to disable
                cookies in Your web browser. Please be aware that the Owner will
                still receive information about You that You have provided, such
                as Your email address. If You choose to terminate Your account,
                the Owner may store some information about You for the future
                reference.
            </p>
            <p >&nbsp;</p>
        </>
    );
};

export default PrivacyPolicyScreen;
