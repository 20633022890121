export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST'
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS'
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL'
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET'

export const ORDER_MY_LIST_REQUEST = 'ORDER_MY_LIST_REQUEST'
export const ORDER_MY_LIST_SUCCESS = 'ORDER_MY_LIST_SUCCESS'
export const ORDER_MY_LIST_FAIL = 'ORDER_MY_LIST_FAIL'
export const ORDER_MY_LIST_RESET = 'ORDER_MY_LIST_RESET'

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL'
export const ORDER_LIST_RESET = 'ORDER_LIST_RESET'

export const ORDER_DELIVER_REQUEST = 'ORDER_DELIVER_REQUEST'
export const ORDER_DELIVER_SUCCESS = 'ORDER_DELIVER_SUCCESS'
export const ORDER_DELIVER_FAIL = 'ORDER_DELIVER_FAIL'
export const ORDER_DELIVER_RESET = 'ORDER_DELIVER_RESET'

export const ORDER_PAID_REQUEST = "ORDER_PAID_REQUEST"
export const ORDER_INVOICE_SUCCESS = "ORDER_INVOICE_SUCCESS"
export const ORDER_INVOICE_FAIL = "ORDER_INVOICE_FAIL"
export const ORDER_INVOICE_REQUEST = "ORDER_INVOICE_REQUEST"

export const VALIDATE_COUPON_REQUEST = "VALIDATE_COUPON_REQUEST"
export const VALIDATE_COUPON_SUCCESS = "VALIDATE_COUPON_SUCCESS"
export const VALIDATE_COUPON_FAIL = "VALIDATE_COUPON_FAIL:"
export const COUPON_RESET_REQUEST = "COUPON_RESET_REQUEST:"

export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST"
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS"
export const ORDER_DELETE_FAIL = "ORDER_DELETE_FAIL:"