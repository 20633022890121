import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import CheckoutSteps from '../components/CheckoutSteps'
import { createOrder, validateCoupon } from '../actions/orderActions'
import { ORDER_CREATE_RESET, COUPON_RESET_REQUEST } from '../constants/orderConstants'

const PlaceOrderScreen = () => {
  const isTestingEnv = JSON.parse(localStorage.getItem("isTestingEnv")) || false;
  const freeShippingFebOffer = true;
  const history = useHistory()
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const cart = useSelector((state) => state.cart)

  const couponValidate = useSelector((state) => state.couponValidate)
  const { loading:validatingCoupon, coupon, error: couponValidateError } = couponValidate;
  
  const [couponCode, setCouponCode] = useState("")
  // Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  )

  cart.shippingPrice = coupon === "FREESHIPPING" ? 0 : addDecimals(((userInfo?.isAdmin && isTestingEnv) || freeShippingFebOffer)?0:(cart.itemsPrice <= 999 ? 45 : 0));
  cart.taxPrice = addDecimals(Number(0.03 * cart.itemsPrice))
  let displayTaxPrice = addDecimals(coupon && coupon!== "FREESHIPPING" ?0.03*(Number(cart.itemsPrice) - (Number(coupon.discount)*Number(cart.itemsPrice)/100)):Number(0.03 * cart.itemsPrice))

  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2)

  let displayTotalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(displayTaxPrice) - (Number(coupon && coupon!== "FREESHIPPING" ?((Number(coupon.discount)*Number(cart.itemsPrice)/100)):0)).toFixed(2)
  ).toFixed(2)

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success, error } = orderCreate

  useEffect(() => {
    dispatch({ type: COUPON_RESET_REQUEST });
  }, [dispatch])

  
  useEffect(() => {
    if (success) {
      history.push(`/order/${order._id}`)
      dispatch({ type: ORDER_CREATE_RESET })
    }
    // eslint-disable-next-line
  }, [history, success, dispatch])

  const placeOrderHadler = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod || "Paytm Payment Gateway",
        itemsPrice: cart.itemsPrice,
        taxPrice: cart.taxPrice,
        shippingPrice: cart.shippingPrice,
        couponCode,
        userInfo,
        totalPrice: cart.totalPrice,
      })
    )
  }

  const onApplyCoupon = () => {
    dispatch(
      validateCoupon({
        couponCode,
        itemsPrice: cart.itemsPrice})
    )
  }
  return (
    <>
      <CheckoutSteps step1 step2 step3 />
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroupItem>
              <h2>Shipping</h2>
              <p>
                <strong>Address: </strong>
                {cart.shippingAddress.phone}, {cart.shippingAddress.address}, {cart.shippingAddress.city}{' '}
                {cart.shippingAddress.postalCode},{' '}
                {cart.shippingAddress.country}
              </p>
            </ListGroupItem>

            {/* <ListGroupItem>
              <h2>Payment Method</h2>
              <strong>Method: </strong>
              {cart.paymentMethod ? (
                `${cart.paymentMethod} or Credit Card`
              ) : (
                <span className='method-warning'>
                  Payment method not selected
                </span>
              )}
            </ListGroupItem> */}

            <ListGroupItem>
              <h2>Order Items</h2>
              {cart.cartItems.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                <ListGroup variant='flush'>
                  {cart.cartItems.map((item, index) => (
                    <ListGroupItem key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x &#8377;{item.price} = &#8377;{item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              )}
            </ListGroupItem>
          </ListGroup>
        </Col>

        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroupItem>
                <h2>Order Summary</h2>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col>Items</Col>
                  <Col>&#8377;{cart.itemsPrice}</Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col>Shipping</Col>
                  <Col>
                    {cart.shippingPrice > 0 ? (
                      <p>&#8377;{cart.shippingPrice}</p>
                    ) : (
                      'Free Shipping'
                    )}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col>Tax</Col>
                  <Col>&#8377;{displayTaxPrice}</Col>
                </Row>
              </ListGroupItem>
              {coupon && coupon!== "FREESHIPPING" && <ListGroupItem>
                <Row>
                  <Col>Discount</Col>
                  <Col>&#8377;{(((Number(cart.itemsPrice)*Number(coupon.discount))/100)).toFixed(2)}</Col>
                </Row>
              </ListGroupItem>}
              <ListGroupItem>
                <Row>
                  <Col>Total</Col>
                  <Col>&#8377;{displayTotalPrice}</Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                {(error || couponValidateError) && <Message variant='danger'>{error || couponValidateError}</Message>}
              </ListGroupItem>
             {(!validatingCoupon && coupon) && <ListGroupItem>
                <Message variant='success'>Coupon Code Applied</Message>
              </ListGroupItem>}
              <ListGroupItem>
              <Row>
                  <Col>
                  <input style={{height: "100%", textAlign: "center"}} type="text" id="couponCode" name="couponCode" placeholder="Coupon Code" onChange={(e) => (setCouponCode(e.target.value))} value={couponCode}></input>
                  </Col>
                  <Col>
                    <Button
                    type='button'
                    className='btn-block'
                    disabled={cart.cartItems === 0}
                    onClick={onApplyCoupon}
                  >
                    Apply Coupon
                  </Button>
                  </Col>
                </Row>
                
              </ListGroupItem>
              <ListGroupItem>
                <Button
                  type='button'
                  className="btn btn-block"
                  disabled={cart.cartItems === 0}
                  onClick={placeOrderHadler}
                >
                  Continue To Payment
                </Button>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default PlaceOrderScreen
