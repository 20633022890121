import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Carousel, Image } from "react-bootstrap";
import Loader from "./Loader";
import Message from "./Message";
import { useDispatch, useSelector } from "react-redux";
import { allCategories } from "../actions/categoriesAction";
import { allBanners } from "../actions/bannerActions";

const ProductCarousel = () => {
    const dispatch = useDispatch();

    const categorysAll = useSelector((state) => state.categoryAll);
    const { loading, error, categories } = categorysAll;
    const bannerList = useSelector((state) => state.bannerAll);
    const { loading: loadingBanner, error: errorBanner, banners } = bannerList;
    useEffect(() => {
        dispatch(allCategories());
        dispatch(allBanners());
    }, [dispatch]);

    return loading && loadingBanner ? (
        <Loader />
    ) : error && errorBanner ? (
        <Message variant="danger">{error}</Message>
    ) : (
        <Carousel pause="hover" style={{ margin: "-35px" }}>
            {banners.map(
                (banner) =>
                    banner.showBanner &&
                    (banner.bannerLink ? (
                        <Carousel.Item key={banner._id}>
                            <Link
                                to={{ pathname: banner.bannerLink }}
                                target="_blank"
                            >
                                <Image
                                    src={banner.banner}
                                    alt={banner.name}
                                    fluid
                                    className="d-block w-100 h-100 rounded-0"
                                />
                                <Carousel.Caption className="carousel-caption">
                                    <h4>{"Free Shipping"}</h4>
                                </Carousel.Caption>
                            </Link>
                        </Carousel.Item>
                    ) : (
                        <Carousel.Item key={banner._id}>
                            <Image
                                src={banner.banner}
                                alt={banner.name}
                                fluid
                                className="d-block w-100 h-100 rounded-0"
                            />
                            <Carousel.Caption className="carousel-caption">
                                <h4>{"Free Shipping"}</h4>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))
            )}
            {categories.map((category) => {
                return (
                    category.showBanner && (
                        <Carousel.Item key={category._id}>
                            <Link to={`/products/${category.name}`}>
                                <Image
                                    src={category.banner}
                                    alt={category.name}
                                    fluid
                                    className="d-block w-100 h-100 rounded-0"
                                />
                                <Carousel.Caption className="carousel-caption">
                                    <h4>{category.name})</h4>
                                </Carousel.Caption>
                            </Link>
                        </Carousel.Item>
                    )
                );
            })}
        </Carousel>
    );
};

export default ProductCarousel;
