import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { logout } from '../actions/userActions'
import SearchBox from './SearchBox'

const Header = () => {
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const [showBanner, setShowBanner] = useState(true)

  const logoutHandler = () => {
    dispatch(logout())
  }

  const messages = [
    "Free Shipping on all orders above Rs 999 💝",
    "Shop more save more: Shop for 999 n above get 15% Off, Use Code: RAKHISALE15",
    "Shop for 1999 n above get 20% Off, Use Code: RAKHISALE20",
  ];

  const concatenatedMessages = messages.join('          ');  // Combine messages with a separator


  return (
    <header>
      {showBanner &&  <aside className="bandeau">
      <p className="banner-body" style={{ "maxWidth": "90%" }}>
        <span className="bandeau-text">{concatenatedMessages}</span>
      </p>
      <button onClick={() => setShowBanner(false)} className='banner-button'>X</button>
    </aside>}
      <Navbar bg='light' variant='light' expand='lg' collapseOnSelect>
        <Container>
          <div className='title-search'>
          <LinkContainer to='/'>
            <Navbar.Brand>GehnaGali</Navbar.Brand>
          </LinkContainer>
          <SearchBox/>
          </div>

          <div >
          <div className='header-cart_container'>
            <LinkContainer to='/cart' className="cart_collapsed">
              <Nav.Link>
                <i className='fa items fa-lg' value={cartItems.length || 0}>&#xf07a;</i>
              </Nav.Link>
            </LinkContainer>
              <Nav className='ml-auto'>
                <LinkContainer to='/cart' className="cart_open">
                  <Nav.Link>
                    <i className='fa items fa-lg' value={cartItems.length || 0}>&#xf07a;</i>
                  </Nav.Link>
                </LinkContainer>
                <div className='sign-in-container'>

                {userInfo ? (
                  <NavDropdown title={userInfo.name} id='username'>
                    <LinkContainer to='/profile'>
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={logoutHandler}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <LinkContainer to='/login'>
                    <Nav.Link>
                      <i className='fas fa-user'></i> Sign In
                    </Nav.Link>
                  </LinkContainer>
                )}
                {userInfo && userInfo.isAdmin && (
                  <NavDropdown title='Admin' id='adminmenu'>
                    <LinkContainer to='/admin/userList'>
                      <NavDropdown.Item>Users</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/admin/productList'>
                      <NavDropdown.Item>Products</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/admin/orderList'>
                      <NavDropdown.Item>Orders</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/admin/categorylist'>
                      <NavDropdown.Item>Categories</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/admin/bannerList'>
                      <NavDropdown.Item>Banners</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/admin/couponList'>
                      <NavDropdown.Item>Coupons</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                )}
               </div>

              </Nav>
              
          </div>
          </div>

        </Container>
      </Navbar>
    </header>
  )
}

export default Header
