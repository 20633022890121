import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  Form,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listCategoryDetails, updateCateory } from '../actions/categoriesAction'
import { CATEGORY_UPDATE_RESET } from '../constants/categoryConstants'

const CategoryEditScreen = () => {
  const history = useHistory()
  const { id } = useParams()

  const [name, setName] = useState('')
  const [image, setImage] = useState('')
  const [showBanner, setShowBanner] = useState(false)
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  const categoryDetails = useSelector((state) => state.categoryDetails)
  const { loading, error, category } = categoryDetails

  const categoryUpdate = useSelector((state) => state.categoryUpdate)
  const {
    loading: loadingUpdate,
    error: errrorUpdate,
    success: successUpdate,
  } = categoryUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: CATEGORY_UPDATE_RESET })
      history.push('/admin/categorylist')
    } else {
      if (!category.name || category._id !== id) {
        dispatch(listCategoryDetails(id))
      } else {
        setName(category.name)
        setImage(category.banner)
        setShowBanner(category.showBanner)
      }
    }
  }, [category, id, dispatch, successUpdate, history])

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)
      setImage(data.urlList)
      setUploading(false)
    } catch (error) {
      console.log(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
        updateCateory({
        _id: id,
        name,
        banner: image,
        showBanner,
      })
    )
  }
  return (
    <>
      <Link to='/admin/categoryList' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Category</h1>
        {loadingUpdate && <Loader />}
        {errrorUpdate && <Message variant='danger'>{errrorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <FormGroup controlId='name'>
              <FormLabel>Name</FormLabel>
              <FormControl
                type='text'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></FormControl>
            </FormGroup>

            <FormGroup controlId='image'>
              <FormLabel>banner</FormLabel>
              <FormControl
                type='text'
                placeholder='Enter image url'
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></FormControl>
              <Form.File
                id='image-file'
                label='Choose File'
                custom
                onChange={uploadFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </FormGroup>

            <FormGroup controlId='description'>
              <FormLabel>Show Banner</FormLabel>
              <FormControl
                type='checkbox'
                checked={showBanner}
                onChange={(e) => setShowBanner(e.target.checked)}
              ></FormControl>
            </FormGroup>

            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default CategoryEditScreen
