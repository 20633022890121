import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Footer from './components/Footer'
import Header from './components/Header'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import ShippingScreen from './screens/ShippingScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import CategoryEditScreen from './screens/CategoryEditScreen'
import ProductListScreen from './screens/ProductListScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import OrderListScreen from './screens/OrderListScreen'
import CategoryHeader from './components/CategoryHeader'
import RingScreen from './screens/RingScreen'
import ContactScreen from './screens/ContactScreen'
import AboutScreen from './screens/AboutScreen'
import axios from 'axios';
import CategoryListScreen from './components/CategoryListScreen'
import ProductCreateScreen from './screens/ProductCreateScreen'
import CategoryCreateScreen from './screens/CategoryCreateScreen'
import BannerCreateScreen from './screens/BannerCreateScreen'
import BannerListScreen from './screens/BannerListScreen'
import BannerEditScreen from './screens/BannerEditScreen'
import CouponCreateScreen from './screens/CouponCreateScreen'
import CouponListScreen from './screens/CouponListScreen'
import CouponEditScreen from './screens/CouponEditScreen'
import CategoryScreen from './screens/CategoryScreen'
import ReturnAndExchangePolicyScreen from './screens/ReturnAndExchangePolicyScreen'
import TermsAndConditionsScreen from './screens/TermsAndConditionsScreen'
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen'
import ScrollToTop from './utils'

if(window.location.host.endsWith("gehnagali.com") || window.location.host.endsWith("web.app")){
  axios.defaults.baseURL = "https://us-central1-gehnagali-3be0b.cloudfunctions.net/gehnagali";
}

const App = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Header />
      <CategoryHeader />
      <main className='py-3'>
        <Container>
          <Route path='/about' component={AboutScreen} />
          <Route path='/contact' component={ContactScreen} />
          {/* <Route path='/products/earrings' component={EarringScreen} />
          <Route path='/products/necklaces' component={NecklaceScreen} />
          <Route path='/products/bracelets' component={BraceletScreen} /> */}
          <Route path='/products/:category' component={CategoryScreen} />
          <Route path='/products/rings' component={RingScreen} />
          <Route path='/admin/product/:id/edit' component={ProductEditScreen} />
          <Route path='/admin/product/create' component={ProductCreateScreen} />
          <Route path='/admin/category/create' component={CategoryCreateScreen} />
          <Route path='/admin/banner/create' component={BannerCreateScreen} />
          <Route path='/admin/bannerList' component={BannerListScreen} />
          <Route path='/admin/banner/:id/edit' component={BannerEditScreen} />
          <Route path='/admin/coupon/create' component={CouponCreateScreen} />
          <Route path='/admin/couponList' component={CouponListScreen} />
          <Route path='/admin/coupon/:id/edit' component={CouponEditScreen} />
          <Route
            path='/admin/productlist'
            component={ProductListScreen}
            exact
          />
          <Route
            path='/admin/productlist/:pageNumber'
            component={ProductListScreen}
            exact
          />
          <Route path='/admin/user/:id/edit' component={UserEditScreen} />
          <Route path='/admin/userlist' component={UserListScreen} />
          <Route path='/admin/categorylist' component={CategoryListScreen} />
          <Route path='/admin/category/:id/edit' component={CategoryEditScreen} />
          <Route path='/admin/orderlist' component={OrderListScreen} />
          <Route path='/order/:id' component={OrderScreen} />
          <Route path='/placeorder' component={PlaceOrderScreen} />
          <Route path='/shipping' component={ShippingScreen} />
          <Route path='/register' component={RegisterScreen} />
          <Route path='/login' component={LoginScreen} />
          <Route path='/profile' component={ProfileScreen} />
          <Route path='/product/:id' component={ProductScreen} />
          <Route path='/cart/:id?' component={CartScreen} />
          <Route path='/search/:keyword' component={HomeScreen} exact />
          <Route path='/page/:pageNumber' component={HomeScreen} />
          <Route
            path='/search/:keyword/page/:pageNumber'
            component={HomeScreen}
          />
          <Route path='/' component={HomeScreen} exact />
          <Route path='/return' component={ReturnAndExchangePolicyScreen} exact />
          <Route path='/tnc' component={TermsAndConditionsScreen} exact />
          <Route path='/privacy' component={PrivacyPolicyScreen} exact />

        </Container>
      </main>
      <Footer />
    </Router>
  )
}

export default App
