export const COUPON_CREATE_REQUEST = "COUPON_CREATE_REQUEST";
export const COUPON_CREATE_SUCCESS = "COUPON_CREATE_SUCCESS";
export const COUPON_CREATE_FAIL = "COUPON_CREATE_FAIL";
export const COUPON_CREATE_RESET = "COUPON_CREATE_RESET";

export const COUPON_ALL_REQUEST = "COUPON_ALL_REQUEST";
export const COUPON_ALL_SUCCESS = "COUPON_ALL_SUCCESS";
export const COUPON_ALL_FAIL = "COUPON_ALL_FAIL";

export const COUPON_LIST_REQUEST = "COUPON_LIST_REQUEST";
export const COUPON_LIST_SUCCESS = "COUPON_LIST_SUCCESS";
export const COUPON_LIST_FAIL = "COUPON_LIST_FAIL";

export const COUPON_DETAILS_REQUEST = "COUPON_DETAILS_REQUEST";
export const COUPON_DETAILS_SUCCESS = "COUPON_DETAILS_SUCCESS";
export const COUPON_DETAILS_FAIL = "COUPON_DETAILS_FAIL";

export const COUPON_DELETE_REQUEST = "COUPON_DELETE_REQUEST";
export const COUPON_DELETE_SUCCESS = "COUPON_DELETE_SUCCESS";
export const COUPON_DELETE_FAIL = "COUPON_DELETE_FAIL";

export const COUPON_UPDATE_REQUEST = "COUPON_UPDATE_REQUEST";
export const COUPON_UPDATE_SUCCESS = "COUPON_UPDATE_SUCCESS";
export const COUPON_UPDATE_FAIL = "COUPON_UPDATE_FAIL";
export const COUPON_UPDATE_RESET = "COUPON_UPDATE_RESET";
