import React from "react";
import styled from "styled-components";
import { SocialIcon } from "react-social-icons";
import { Link } from 'react-router-dom'

const FooterWrapper = styled.footer`
    background-color: #f2f2f2;
    padding: 45px 0 0px;
    font-size: 15px;
    line-height: 24px;
    color: #737373;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding-bottom: 5px;
    margin: 0px 15px;
    @media only screen and (max-width: 600px) {
        flex-direction: column;
}
`;

const Divider = styled.div`
    display: block;
    height: 3px;
    margin: 1em 0;
    max-width: 30px;
    width: 100%;
    background: #828282;
`;

const HeadingSection = styled.div``;
const Heading = styled.h5``;

const CompanySection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const CustomerCareSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media only screen and (max-width: 600px) {
        margin: 30px 0px;
}
    
`;

const ContactUsSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const FooterEndSection = styled.div`
`;
const CopyRightSectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
    margin: 0px 10px;
`;

const SocialMediaSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px 10px;
    padding-top: 5px;
`;

const SocialMediaIconWrapper = styled.div`
    margin: 0px 10px;
`;
const MadeWithLoveSection = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    background: #e9e9e9;
    padding-bottom: 10px;
    a{
        margin: 0px 5px;
    }
`;

const HR = styled.hr`
margin: 1px;
`

const Footer = () => {
    return (
        <footer>
            <FooterWrapper>
                <ContentWrapper>
                    <CompanySection>
                        <HeadingSection>
                            <Heading>The Company</Heading>
                            <Divider />
                        </HeadingSection>
                        <Link to="/about">About US</Link>
                    </CompanySection>
                    <CustomerCareSection>
                        <HeadingSection>
                            <Heading>Customer Care</Heading>
                            <Divider />
                        </HeadingSection>
                        <Link to="/return">
                            Return & Exchange Policy
                        </Link>
                        <Link to="/tnc">
                            Terms & Conditions
                        </Link>
                        <Link to="/privacy">
                            Privacy Policy
                        </Link>
                    </CustomerCareSection>
                    <ContactUsSection>
                        <HeadingSection>
                            <Heading>Get IN Touch</Heading>
                            <Divider />
                        </HeadingSection>
                        <Link to="/contact">Contact us</Link>
                    </ContactUsSection>
                </ContentWrapper>
                <FooterEndSection>
                <HR/>

                    <CopyRightSectionWrapper>
                        Copyright {new Date().getFullYear()} @Gehnagali
                        <SocialMediaSection>
                            <SocialMediaIconWrapper>
                                <SocialIcon url="https://www.facebook.com/Gehnagali-102775655938718/" target="_blank" rel="noopener noreferrer" />
                            </SocialMediaIconWrapper>
                            <SocialMediaIconWrapper>
                                <SocialIcon url="https://www.instagram.com/gehna.gali_by_swati/"  target="_blank" rel="noopener noreferrer" />
                            </SocialMediaIconWrapper>
                        </SocialMediaSection>
                    </CopyRightSectionWrapper>
                    <MadeWithLoveSection>
                        Made with ❤ by{" "}
                        <a href="https://calledrahul.in">Rahul</a>
                    </MadeWithLoveSection>
                </FooterEndSection>
            </FooterWrapper>
        </footer>
    );
};

export default Footer;
