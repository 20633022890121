import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
    listCoupons,
    deleteCoupon,
    listCouponDetails,
    allCoupons
} from "../actions/couponActions";
import {COUPON_CREATE_RESET } from "../constants/couponConstants";
import Paginate from "../components/Paginate";

const CouponsListScreen = () => {
    const history = useHistory();
    const { pageNumber } = useParams() || 1;
    const dispatch = useDispatch();

    const couponList = useSelector((state) => state.couponList);
    const { loading, error, coupons, pages, page } = couponList;

    const couponDelete = useSelector((state) => state.couponDelete);
    const {
        loading: loadingDelete,
        error: errorDelete,
        success: successDelete,
    } = couponDelete;

    const couponCreate = useSelector((state) => state.couponCreate);
    const {
        loading: loadingCreate,
        error: errorCreate,
        success: successCreate,
        coupon: createdCoupon,
    } = couponCreate;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        dispatch({ type:COUPON_CREATE_RESET });
        dispatch(allCoupons());
        if (!userInfo || !userInfo.isAdmin) {
            history.push("/login");
        }
        if (successCreate) {
            history.push(`/admin/coupon/${createdCoupon._id}/edit`);
            
        } else {
            dispatch(listCoupons("", pageNumber));
        }
    }, [
        dispatch,
        history,
        userInfo,
        successDelete,
        successCreate,
        createdCoupon,
        pageNumber,
    ]);

    const deleteHandler = (id) => {
        if (window.confirm("Are you sure you want to delete this coupon?")) {
            dispatch(deleteCoupon(id));
        }
    };

    return (
        <>
            <Row className="align-items-center">
                <Col>
                    <h1>Coupons</h1>
                </Col>
                <Col className="text-right">
                <LinkContainer to={`/admin/coupon/create`}>                  
                    <Button className="my-3" 
                    >
                        <i className="fas fa-plus"></i> Create Coupon
                    </Button>
                  </LinkContainer>
                </Col>
            </Row>
            {loadingDelete && <Loader />}
            {errorDelete && <Message variant="danger">{errorDelete}</Message>}
            {loadingCreate && <Loader />}
            {errorCreate && <Message variant="danger">{errorCreate}</Message>}
            {loading ? (
                <Loader />
            ) : error ? (
                <Message variant="danger">{error}</Message>
            ) : (
                <>
                    <Table
                        striped
                        bordered
                        hover
                        responsive
                        className="table-sm"
                    >
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>Discount %</th>
                                <th>Minimum Purchase</th>
                                <th>Expiry</th>
                                <th>Active</th>
                                <th>For Email</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {coupons.map((coupon) => (
                                <tr key={coupon._id}>
                                    <td>{coupon.name}</td>
                                    <td>{coupon.discount}</td>
                                    <td>{coupon.minimumPurchase}</td>
                                    <td>{new Date(coupon.expiryDate).toISOString().slice(0, 10)}</td>
                                    <td>{coupon.isActive?'Yes':'No'}</td>
                                    <td>{coupon.forEmail?'Yes':'No'}</td>

                                    <td>
                                        <LinkContainer
                                            to={`/admin/coupon/${coupon._id}/edit`}
                                        >
                                            <Button
                                            onClick={() =>
                                                dispatch(
                                                    listCouponDetails(
                                                        coupon._id
                                                    )
                                                )
                                            }
                                                variant="light"
                                                className="btn-sm"
                                            >
                                                <i className="fas fa-edit"></i>
                                            </Button>
                                        </LinkContainer>
                                        <Button
                                            variant="danger"
                                            className="btn-sm"
                                            onClick={() =>
                                                deleteHandler(coupon._id)
                                            }
                                        >
                                            <i className="fas fa-trash"></i>
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Paginate pages={pages} page={page} isAdmin={true} />
                </>
            )}
        </>
    );
};

export default CouponsListScreen;
