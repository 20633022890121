import React from "react";
import FormContainer from "../components/FormContainer";
import { Form, Button, FormGroup } from "react-bootstrap";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useNotification from "../components/Notification";

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
`;

const FormWrapper = styled.div`
    width: 100%;
`;

const ContactInformation = styled.div`
    color: #817d7d !important;
`;

const AddressWrapper = styled.div``;
const PhoneWrapper = styled.div``;
const EmailWrapper = styled.div``;
const OwnerNameWrapper = styled.div``;
const Info = styled.h6`
    color: #817d7d !important;
    font-size: 1rem;
`;
const Heading = styled.h6`
    margin: 0px;
    margin-top: 10px;
    font-size: 1rem;
`;
const Value = styled.div`
    font-size: 1rem;
`;

const ContactScreen = () => {
    const [Notification, addNotification] = useNotification();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_geazifc",
                "template_rrtqvkt",
                e.target,
                "user_JJBuHjMgjHEWGzEtakIyH"
            )
            .then(
                (result) => {
                    addNotification(
                        `Thanks for your message! I'll Revert back to soon.😀`,
                        "success"
                    );
                },
                (error) => {
                    addNotification(
                        "Something went wrong. Try again!",
                        "error"
                    );
                }
            );
        e.target.reset();
    };

    return (
        <>
            <Link to="/" className="btn btn-light">
                Go Back
            </Link>
            <ContentWrapper>
                <FormWrapper>
                    <FormContainer>
                        <h1>Contact Us</h1>
                        <Form onSubmit={sendEmail}>
                            <FormGroup controlId="name">
                                <label for="name">NAME</label>
                                <input
                                    type="name"
                                    className="form-control"
                                    id="name"
                                    placeholder="Enter Name"
                                    name="name"
                                    required
                                />
                            </FormGroup>
                            <FormGroup controlId="email">
                                <label for="email">EMAIL</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="name@example.com"
                                    name="email"
                                    required
                                />
                            </FormGroup>
                            <FormGroup controlId="subject">
                                <label for="subject">SUBJECT</label>
                                <input
                                    type="subject"
                                    className="form-control"
                                    id="subject"
                                    placeholder="Enter subject"
                                    name="subject"
                                    required
                                />
                            </FormGroup>
                            <FormGroup controlId="message">
                                {" "}
                                <label for="messsage">MESSAGE</label>
                                <textarea
                                    className="form-control"
                                    id="messsage"
                                    rows="5"
                                    name="message"
                                    required
                                ></textarea>
                            </FormGroup>
                            <Button type="submit" variant="primary">
                                Send Email
                            </Button>
                        </Form>
                        <Notification />
                    </FormContainer>
                </FormWrapper>
                <ContactInformation>
                    <OwnerNameWrapper>
                        <Info>Head Office-not available for visits</Info>

                        <Heading>Owner Name :</Heading>
                        <Value>Swati Rathore</Value>
                    </OwnerNameWrapper>
                    <AddressWrapper>
                        <Heading>Address :</Heading>
                        <Value>Jaipur, Rajasthan. 302029</Value>
                    </AddressWrapper>

                    <PhoneWrapper>
                        <Heading>Tel :</Heading>
                        <Value>8800906508</Value>
                    </PhoneWrapper>
                    <EmailWrapper>
                        <Heading>Email :</Heading>
                        <Value>connect@gehnagali.com</Value>
                    </EmailWrapper>
                </ContactInformation>
            </ContentWrapper>
        </>
    );
};

export default ContactScreen;

// <span>(Head Office-not available for visits)</span>
// <br />
// <strong>Jaipur, Rajasthan</strong>
// <br />
// <span>Rajasthan Pin 302029</span>
// <br />
// <span>Tel : 8800906508</span>
// <br />
// <span>connect@gehnagali.com</span>
