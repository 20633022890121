import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    Form,
    Button,
    FormGroup,
    FormLabel,
    FormControl,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { createProduct } from "../actions/productActions";
import { PRODUCT_CREATE_RESET } from "../constants/productConstants";
import { allCategories } from "../actions/categoriesAction";

const ProductCreateScreen = () => {
    const history = useHistory();

    const [name, setName] = useState("");
    const [imageUploadStatus, setImageUploadStatus] = useState("");
    const [brand, setBrand] = useState("");
    const [category, setCategory] = useState("");
    const [description, setDescription] = useState("");
    const [showProduct, setShowProduct] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [productVariants, setProductVariants] = useState([
        {
            color: "",
            image: "",
            price: "",
            originalPrice: "",
            countInStock: "",
            discount: 0,
            showVarient: false,
        },
    ]);

    const dispatch = useDispatch();

    const productCreate = useSelector((state) => state.productCreate);
    const categorysAll = useSelector((state) => state.categoryAll);

    const {
        loading: loadingCreate,
        error: errorCreate,
        success: successCreate,
    } = productCreate;

    const { loading, categories } = categorysAll;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        dispatch(allCategories());
    }, [dispatch]);

    useEffect(() => {
        setCategory(categories[0]?.name || "Chokers");
    }, [categories]);

    useEffect(() => {
        dispatch({ type: PRODUCT_CREATE_RESET });

        if (!userInfo || !userInfo.isAdmin) {
            history.push("/login");
        }
        if (successCreate) {
            history.push("/admin/productlist");
        }
    }, [dispatch, history, userInfo, successCreate]);

    const uploadFileHandler = async (index, e) => {
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            formData.append("image", e.target.files[i])
          }
        setUploading(true);
        try {
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            const {data} = await axios.post("/api/upload", formData, config);
            let inputData = [...productVariants];
            inputData[index]["image"] = data.urlList;
            setImageUploadStatus(data.message)
            setProductVariants(inputData);
            setUploading(false);
        } catch (error) {
            console.log(error);
            setImageUploadStatus("Something went wrong. Choose only png, jpg or jpeg format images")
            setUploading(false);
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(
            createProduct({
                name,
                brand,
                category,
                description,
                showProduct,
                productVariants,
            })
        );
    };

    const handleFormChange = (index, event) => {
        let data = [...productVariants];
        data[index][event.target.name] =
            event.target.name === "showVarient"
                ? event.target.checked
                : event.target.value;
        console.log(event.target.checked);
        setProductVariants(data);
    };

    const addFields = () => {
        let newfield = { color: "", image: "", price: "", originalPrice: "", countInStock: "", showVarient: false };
        setProductVariants([...productVariants, newfield]);
    };

    const removeFields = (index) => {
        let data = [...productVariants];
        data.splice(index, 1);
        setProductVariants(data);
    };

    return (
        <>
            <Link to="/admin/productList" className="btn btn-light my-3">
                Go Back
            </Link>
            <FormContainer>
                <h1>Create Product</h1>
                {loadingCreate ? (
                    <Loader />
                ) : errorCreate ? (
                    <Message variant="danger">{errorCreate}</Message>
                ) : (
                    <Form onSubmit={submitHandler}>
                        <FormGroup controlId="name">
                            <FormLabel>Name</FormLabel>
                            <FormControl
                                type="text"
                                placeholder="Enter name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            ></FormControl>
                        </FormGroup>

                        <FormGroup controlId="brand">
                            <FormLabel>Brand</FormLabel>
                            <FormControl
                                type="text"
                                placeholder="Enter brand"
                                value={brand}
                                onChange={(e) => setBrand(e.target.value)}
                            ></FormControl>
                        </FormGroup>

                        {loading ? (
                            <Loader />
                        ) : (
                            <FormGroup controlId="category">
                                <FormLabel>Category</FormLabel>
                                <Form.Control
                                    as="select"
                                    value={category}
                                    onChange={(e) => {
                                        setCategory(e.target.value);
                                    }}
                                >
                                    {categories?.map((category) => (
                                        <option value={category.name}>
                                            {category.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </FormGroup>
                        )}

                        <FormGroup controlId="description">
                            <FormLabel>Description</FormLabel>
                            <FormControl
                                type="description"
                                placeholder="Enter description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            ></FormControl>
                        </FormGroup>
                        <FormGroup controlId="showProduct">
                            <FormLabel>Show Product</FormLabel>
                            <FormControl
                                type="checkbox"
                                checked={showProduct}
                                onChange={(e) =>
                                    setShowProduct(e.target.checked)
                                }
                            ></FormControl>
                        </FormGroup>

                        <h3>Enter product varients</h3>

                        {productVariants.map((input, index) => {
                            return (
                                <div className="product-container" key={index}>
                                    <FormGroup controlId={`color${index}`}>
                                        <FormLabel>Color</FormLabel>
                                        <FormControl
                                            type="text"
                                            name='color'
                                            placeholder="Enter color"
                                            value={input.color}
                                            onChange={(event) =>
                                                handleFormChange(index, event)
                                            }
                                        ></FormControl>
                                    </FormGroup>

                                    <FormGroup controlId={`originalPrice${index}`}>
                                        <FormLabel>Original Price</FormLabel>
                                        <FormControl
                                            type="number"
                                            name='originalPrice'
                                            placeholder="Enter price"
                                            value={input.originalPrice}
                                            onChange={(event) =>
                                                handleFormChange(index, event)
                                            }
                                        ></FormControl>
                                    </FormGroup>

                                    <FormGroup controlId={`price${index}`}>
                                        <FormLabel>Price</FormLabel>
                                        <FormControl
                                            type="number"
                                            name='price'
                                            placeholder="Enter price"
                                            value={input.price}
                                            onChange={(event) =>
                                                handleFormChange(index, event)
                                            }
                                        ></FormControl>
                                    </FormGroup>


                                    <FormGroup controlId={`image${index}`}>
                                        <FormLabel>Images</FormLabel>
                                       <h4>{imageUploadStatus}</h4>
                                       <FormControl
                                            type="text"
                                            name="image"
                                            placeholder="image url"
                                            value={input.image}
                                            onChange={(event) =>
                                                handleFormChange(index, event)
                                            }
                                        ></FormControl>
                                        <Form.File
                                            id={`image-file${index}`}
                                            label="Choose Images"
                                            multiple
                                            onChange={(event) => {uploadFileHandler(index, event)}}
                                        ></Form.File>
                                        {uploading && <Loader />}
                                    </FormGroup>
                                    <FormGroup controlId={`countInStock${index}`}>
                                        <FormLabel>Count In Stock</FormLabel>
                                        <FormControl
                                            type="number"
                                            name='countInStock'
                                            placeholder="Enter countInStock"
                                            value={input.countInStock}
                                            onChange={(event) =>
                                                handleFormChange(index, event)
                                            }
                                        ></FormControl>
                                    </FormGroup>
                                    <FormGroup controlId="showVarient">
                                        <FormLabel>Show Varient</FormLabel>
                                        <FormControl
                                            type="checkbox"
                                            name="showVarient"
                                            checked={input.showVarient}
                                            onChange={(event) =>
                                                handleFormChange(index, event)
                                            }
                                        ></FormControl>
                                    </FormGroup>
                                    <Button onClick={() => removeFields(index)} variant="danger">
                                        Remove
                                    </Button>
                                    <hr/>
                                </div>
                            );
                        })}

                        <Button type="button" onClick={addFields}>
                            Add More Varient
                        </Button>

                        <h2>Or</h2>

                        <Button type="submit" variant="primary">
                            Create Product listing
                        </Button>
                    </Form>
                )}
            </FormContainer>
        </>
    );
};

export default ProductCreateScreen;
