import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { allProducts } from "../actions/productActions";
import Meta from "../components/Meta";
import Product from "../components/Product";
import { Link, useParams } from "react-router-dom";


const CategoryScreen = () => {
    const dispatch = useDispatch();
    const { category } = useParams();

    const productAll = useSelector((state) => state.productAll);
    const { loading, error, products } = productAll;
    const filteredProducts =
        products &&
        products.filter((product) => (product.category.toLowerCase() === category.toLowerCase() && product.showProduct));

    useEffect(() => {
        dispatch(allProducts());
    }, [dispatch]);

    return (
        <>
            <Meta title="jewellery categories" description={`Choose from different${category} categories specailly designed`} canonicalLink="/products/:categories" />
            <Link to="/" className="btn btn-light">
                Go Back
            </Link>
            <h1>{category}</h1>
            {loading ? (
                <Loader />
            ) : error ? (
                <Message variant="danger">{error}</Message>
            ) : (
                <>
                    <Row>
                        {filteredProducts &&
                            filteredProducts.map((product) => (
                                <Col
                                    key={product._id}
                                    sm={12}
                                    md={6}
                                    lg={4}
                                    xl={3}
                                >
                                    <Product product={product} />
                                </Col>
                            ))}
                    </Row>
                </>
            )}
        </>
    );
};

export default CategoryScreen;
